/* ============
 * Groups State
 * ============
 *
 * The state of the groups module.
 */

export default {
  all: [],
  pagination: {
    totalCount: 0,
    totalPages: 1,
    limit: 20,
    current: 1,
    total: 1,
  },
};
