import { HTTP } from '../http';

export default {
  async findAll(searchParams) {
    const { data } = await HTTP.post('/user-groups/search', searchParams);
    if (data.status !== 'success') {
      throw new Error('store.groups.findAll.error');
    }
    return data.data;
  },
  async findById(groupId) {
    const { data } = await HTTP.post('/user-group/retrieveById', { id: groupId });
    if (data.status !== 'success') {
      throw new Error('store.groups.findById.error');
    }
    return data.data;
  },
  async search(searchParams) {
    const params = { ...searchParams };
    params.limit = searchParams.limit.toString();
    params.offset = searchParams.offset.toString();
    const { data } = await HTTP.post('/user-groups/search', params);
    if (data.status !== 'success') {
      throw new Error('store.groups.search.error');
    }
    return data.data;
  },
  async create(item) {
    const { data } = await HTTP.post('/user-group/create', item);
    if (data.status !== 'success') {
      throw new Error('store.groups.create.error');
    }
    return data.data;
  },
  async update(item) {
    const { data } = await HTTP.post('/user-group/update', item);
    if (data.status !== 'success') {
      throw new Error('store.groups.update.error');
    }
    return data.data;
  },
  async addUserToGroup(payload) {
    const { data } = await HTTP.post('/user-group/addUserToGroup', payload);
    if (data.status !== 'success') {
      throw new Error('store.groups.createUser.error', { group: payload.group.id });
    }
    return data.data;
  },
  async deleteUserFromGroup(groupId, userId) {
    const { data } = await HTTP.post('/user-group/deleteUserFromGroup', { group: { id: groupId }, user: { userId } });
    if (data.status !== 'success') {
      throw new Error('store.groups.deleteUserFromGroup.error');
    }
    return data.data;
  },
  async destroy(groupId) {
    const { data } = await HTTP.post('/user-group/delete', { id: groupId });
    if (data.status !== 'success') {
      throw new Error('store.groups.destroy.error');
    }
    return data.data;
  },
};
