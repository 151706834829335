import Vue from 'vue';
import * as types from './types';
import api from '../../../api/auth';
import * as http from '../../../http';
import storage from '../../../utils/localStorageGlobal';

export default {

  check({ commit, dispatch }, session) {
    if (session === undefined) {
      session = JSON.parse(localStorage.getItem('es-session'));
    }
    if (session === undefined || session === null) {
      commit(types.SET_SESSION, { auth: false, guest: false, token: undefined });
      commit(types.SET_PROFILE, {
        options: undefined,
        role: undefined,
        isFinalized: false,
      });
    } else {
      commit(types.SET_SESSION, session);
      http.setAuthorization(session.token);
      Vue.prototype.$stonly.setToken(session.token);
      dispatch('getProfile');
    }
  },
  getProfile({ commit }) {
    return api.getProfile().then((response) => {
      if (response.status === 'success' && response.data.profile) {
        commit(types.SET_PROFILE, response.data.profile);

        localStorage.removeItem('es-profile');
        localStorage.setItem('es-profile', JSON.stringify(response.data.profile));
        return Promise.resolve(response);
      }
      commit(types.SET_PROFILE, {
        options: undefined,
        role: undefined,
        isFinalized: false,
      });
      return Promise.reject(response);
    });
  },

  loginWithSession({ commit, dispatch }, session) {
    dispatch('application/startLoading', null, { root: true });

    // save data into store
    http.setAuthorization(session.token);
    Vue.prototype.$stonly.setToken(session.token);
    commit(types.SET_SESSION, session);

    return dispatch('getProfile').then((response) => {
      storage.clearStorage();
      localStorage.setItem('es-session', JSON.stringify(session));
      localStorage.setItem('es-profile', JSON.stringify(response.data.profile));
      return Promise.resolve(response);
    }).catch((err) => {
      commit(types.SET_SESSION, { auth: false, guest: false, token: undefined });
      commit(types.SET_PROFILE, { options: undefined, role: undefined, isFinalized: false });
      dispatch('application/pushAlert', {
        message: 'store.login.error',
        timeout: 5000,
        type: 'error',
      }, { root: true });
      return Promise.reject(err);
    }).finally(() => {
      dispatch('application/finishLoading', null, { root: true });
    });
  },

  loginWithAuthResponse({ commit, dispatch }, authResponseEncoded) {
    const authResponse = JSON.parse(atob(authResponseEncoded));

    const session = {
      token: authResponse.sessionId,
      guest: false,
      auth: true,
    };

    if (authResponse.currentDomain && authResponse.domainsAvailable.length > 0) {
      commit(types.SET_CURRENT_DOMAIN, authResponse.currentDomain);
      commit(types.SET_DOMAINS, authResponse.domainsAvailable);
      dispatch('application/showSelectDomainDialog', null, { root: true });
    }

    storage.clearStorage();
    localStorage.setItem('es-session', JSON.stringify(session));
    localStorage.setItem('es-profile', JSON.stringify(authResponse.profile));

    // save data into store
    http.setAuthorization(session.token);
    Vue.prototype.$stonly.setToken(session.token);
    commit(types.SET_SESSION, session);

    return Promise.resolve(true);
  },

  simulateLoginFailed({ commit, dispatch }) {
    storage.clearStorage();
    commit(types.SET_SESSION, { auth: false, guest: false, token: undefined });
    commit(types.SET_PROFILE, { options: undefined, role: undefined, isFinalized: false });
    dispatch('application/pushAlert', {
      message: 'store.login.error',
      timeout: 5000,
      type: 'error',
    });
  },

  login({ commit, dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.login(params)
      .then((response) => {
        if (response.status === 'success') {
          const session = {
            auth: true,
            guest: response.data.profile.role === 'signer',
            token: response.data.sessionId,
          };
          // save data into store
          commit(types.SET_SESSION, session);
          commit(types.SET_PROFILE, response.data.profile);
          // set request token
          http.setAuthorization(response.data.sessionId);
          Vue.prototype.$stonly.setToken(response.data.sessionId);
          if (response.data.hasOwnProperty('domainsAvailable') && response.data.domainsAvailable.length > 0) {
            commit(types.SET_DOMAINS, response.data.domainsAvailable);
          }
          if (response.data.hasOwnProperty('currentDomain') && response.data.currentDomain) {
            commit(types.SET_CURRENT_DOMAIN, response.data.currentDomain);
          } else if (response.data.hasOwnProperty('domainsAvailable') && response.data.domainsAvailable.length === 1) {
            commit(types.SET_CURRENT_DOMAIN, response.data.domainsAvailable[0]);
          }
          // store session data in localStorage
          storage.clearStorage();
          localStorage.setItem('es-session', JSON.stringify(session));
          localStorage.setItem('es-profile', JSON.stringify(response.data.profile));
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      })
      .catch((err) => {
        commit(types.SET_SESSION, { auth: false, guest: false, token: undefined });
        commit(types.SET_PROFILE, { options: undefined, role: undefined, isFinalized: false });
        dispatch('application/pushAlert', {
          message: 'store.login.error',
          timeout: 5000,
          type: 'error',
        }, { root: true });
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  stepLogin({ commit, dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.loginWithStepUUID(params)
      .then((response) => {
        if (response.status === 'success') {
          const session = {
            auth: true,
            guest: response.data.profile.role === 'signer',
            token: response.data.sessionId,
          };
          // save data into store
          commit(types.SET_SESSION, session);
          commit(types.SET_PROFILE, response.data.profile);
          // set request token
          http.setAuthorization(response.data.sessionId);
          Vue.prototype.$stonly.setToken(response.data.sessionId);
          // store session data in localSorage
          storage.clearStorage();
          localStorage.setItem('es-session', JSON.stringify(session));
          localStorage.setItem('es-profile', JSON.stringify(response.data.profile));
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      })
      .catch((err) => {
        commit(types.SET_SESSION, { auth: false, guest: false, token: undefined });
        commit(types.SET_PROFILE, { options: undefined, role: undefined, isFinalized: false });
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  checkOauthCode({ commit, dispatch }, oauthCode) {
    return api.checkOauthCode(oauthCode)
      .then((response) => {
        if (response.data.status === 'success') {
          const { data } = response.data;
          if (data.hasOwnProperty('domainsAvailable') && data.domainsAvailable) {
            commit(types.SET_DOMAINS, data.domainsAvailable);
          }
          if (data.hasOwnProperty('currentDomain') && data.currentDomain && data.domainsAvailable.length > 1) {
            commit(types.SET_CURRENT_DOMAIN, data.currentDomain);
            dispatch('application/showSelectDomainDialog', null, { root: true });
          } else if (data.hasOwnProperty('domainsAvailable')) {
            commit(types.SET_CURRENT_DOMAIN, data.domainsAvailable[0]);
          }
        }

        return Promise.resolve(response);
      });
  },

  stepLoginWithAuthResponse({ commit }, authResponseEncoded) {
    const authResponse = JSON.parse(atob(authResponseEncoded));

    if (authResponse && authResponse.message) {
      return Promise.reject(authResponse);
    }

    const session = {
      auth: true,
      guest: authResponse.profile.role === 'signer',
      token: authResponse.sessionId,
    };

    storage.clearStorage();
    localStorage.setItem('es-session', JSON.stringify(session));
    localStorage.setItem('es-profile', JSON.stringify(authResponse.profile));

    // save data into store
    http.setAuthorization(session.token);
    Vue.prototype.$stonly.setToken(session.token);
    commit(types.SET_SESSION, session);
    commit(types.SET_PROFILE, authResponse.profile);

    return Promise.resolve(authResponse);
  },

  thirdPartyLogin({ commit }, params) {
    const session = {
      auth: true,
      guest: true,
      token: params.uuid,
    };
    // save data into store
    commit(types.SET_SESSION, session);
    commit(types.SET_PROFILE, {});
    // set request token
    http.setAuthorization(params.uuid);
    Vue.prototype.$stonly.setToken(params.uuid);

    storage.clearStorage();
    localStorage.setItem('es-session', JSON.stringify(session));
    localStorage.setItem('es-profile', JSON.stringify({}));
    return Promise.resolve(session);
  },

  updateUserInfos({ commit }, user) {
    commit(types.SET_USER_INFOS, user);
  },

  getUserInfos({ commit, dispatch }) {
    dispatch('application/startLoading', null, { root: true });
    return api.getUserInfos()
      .then((response) => {
        if (response.status === 'success') {
          commit(types.SET_USER_INFOS, response.data.account);
          commit(types.SET_USER_HAS_EMAIL, true);
          if (response.data.hasOwnProperty('deskRhFeatures')) {
            localStorage.setItem('deskRhFeatures', JSON.stringify(response.data.deskRhFeatures));
          }
          if (response.data.currentDomain && response.data.domainsAvailable.length > 0) {
            commit(types.SET_CURRENT_DOMAIN, response.data.currentDomain);
            commit(types.SET_DOMAINS, response.data.domainsAvailable);
          }
          if (typeof response.data.isDeskRhDomain !== 'undefined') {
            commit(types.SET_IS_DESKRH_DOMAINS, response.data.isDeskRhDomain);
          } else {
            // add user infos for stonly widget only if is not deskrh domain
            if (response.data.account !== undefined && response.data.account.email) {
              Vue.prototype.$stonly.setIdentify(response.data.account.userId);
              Vue.prototype.$stonly.setEmail(response.data.account.email);
              Vue.prototype.$stonly.setUserId(response.data.account.userId);
              Vue.prototype.$stonly.setIsMainUser(response.data.account.isMainUser === '1');
              Vue.prototype.$stonly.sendData();
            }
          }
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch('application/pushAlert', {
          message: 'store.userInfos.error',
          timeout: 5000,
          type: 'error',
        }, { root: true });
        commit(types.SET_USER_INFOS, undefined);
        commit(types.SET_DOMAINS, undefined);
        commit(types.SET_CURRENT_DOMAIN, undefined);
        commit(types.SET_IS_DESKRH_DOMAINS, false);
        commit(types.SET_USER_HAS_EMAIL, false);
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getUsageDatas({ commit, dispatch }) {
    if (localStorage.getItem('usageDatas') !== undefined) {
      commit(types.SET_USAGE_DATAS, JSON.parse(localStorage.getItem('usageDatas')));
    }

    return api.getUsageDatas()
      .then((response) => {
        if (response.status === 'success') {
          commit(types.SET_USAGE_DATAS, response.data);
          localStorage.setItem('usageDatas', JSON.stringify(response.data));
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.usageDatas.error',
          timeout: 5000,
          type: 'error',
        }, { root: true });
        commit(types.SET_USAGE_DATAS, undefined);
        Promise.reject(error);
      });
  },
  clear({ commit }) {
    commit(types.SET_SESSION, { auth: false, guest: false, token: undefined });
    commit(types.SET_PROFILE, { options: undefined, role: undefined, isFinalized: false });
    commit(types.SET_USAGE_DATAS, undefined);
    commit(types.SET_USER_INFOS, undefined);
    commit(types.SET_DOMAINS, undefined);
    commit(types.SET_CURRENT_DOMAIN, undefined);
    commit(types.SET_IS_DESKRH_DOMAINS, false);
    commit(types.SET_USER_HAS_EMAIL, false);
  },
  clearAll({ commit, dispatch }) {
    dispatch('clear');
    dispatch('ModuleEdocSign/parapheurs/clear', null, { root: true });
    dispatch('ModuleEdocSign/signatory/clear', null, { root: true });
  },
  callApiLogout() {
    return api.logout();
  },
  renewToken({ commit, dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.renewToken(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.renewToken.error',
          timeout: 5000,
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  setCurrentDomain({ commit, dispatch }, domain) {
    return api.selectDomain({ domainId: domain.domainId })
      .then((response) => {
        if (response.data.status === 'success') {
          const session = {
            auth: true,
            guest: false,
            token: response.data.data.sessionId,
          };
          commit(types.SET_SESSION, session);
          commit(types.SET_PROFILE, response.data.data.profile);
          // set request token
          http.setAuthorization(response.data.data.sessionId);
          Vue.prototype.$stonly.setToken(response.data.data.sessionId);
          // store session data in localStorage
          storage.clearStorage();
          localStorage.setItem('es-session', JSON.stringify(session));
          localStorage.setItem('es-profile', JSON.stringify(response.data.data.profile));
          commit(types.SET_CURRENT_DOMAIN, domain);
          dispatch('application/closeSelectDomainDialog', null, { root: true });
        }
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.usageDatas.error',
          timeout: 5000,
          type: 'error',
        });
        commit(types.SET_CURRENT_DOMAIN, undefined);
        Promise.reject(error);
      });
  },
  logout({ dispatch }) {
    storage.clearStorage();
    dispatch('application/resetAlert', null, { root: true });
    api.logout().finally(() => location.reload());
  },
  setDeskRhUserComingFromStepLogin({ commit }, value) {
    commit(types.SET_IS_DESKRH_USER_COMING_FROM_STEP_LOGIN, value);
  },
};
