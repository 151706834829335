/* ============
 * Organizations Actions
 * ============
 *
 * The actions available for the Organizations module.
 */

import OrganizationsApi from '../../../api/organizations';
import * as types from './types';

export default {
  async findSignatoriesById({ dispatch }, organizationId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await OrganizationsApi.findSignatoriesById(organizationId);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async findBySignatoryId({ dispatch }, userId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await OrganizationsApi.findBySignatoryId(userId);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async search({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await OrganizationsApi.search(searchParams).then((response) => {
        const pagination = {
          current: (searchParams.offset + searchParams.limit) / searchParams.limit,
          total: Math.ceil((response.nbOrganizations / searchParams.limit)),
        };
        commit(types.SET_PAGINATION, pagination);
        return response;
      });
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },

  async addSignatoryToOrganisations({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await OrganizationsApi.addSignatoryToOrganisations(payload);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async removeSignatoryFromOrganisations({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await OrganizationsApi.removeSignatoryFromOrganisations(payload);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
};
