import { HTTP } from '../http';

export default {
  /*
    Lister les signataires d'une organisation
    curl -d "{\"organizationId\":\"deskrh-prod-edoc_1234567\"}" -H "Authorization: 49d7901a9267e4796e2a878532fa4ec3" "http://10.211.55.7:85/index.php?api=organization&method=listAffectedSigners"
  */
  async findSignatoriesById(organizationId) {
    const { data } = await HTTP.post('/organization/listAffectedSigners', { organizationId });
    if (data.status !== 'success') {
      throw new Error('store.organizations.findUsersById.error');
    }
    return data.data;
  },

  /*
    Récupérer les organisations d'un signataire
    curl -d "{\"user\":{\"userId\":56826}}"
    -H "Authorization: 0fa1297a92325e9a78a6c0256abecfe6"
    "https://develop-22.ztp.edocsign.fr/api/V1/account/listAffectedOrganizations"
  */
  async findBySignatoryId(userId) {
    const { data } = await HTTP.post('/account/listAffectedOrganizations', { user: { userId } });
    if (data.status !== 'success') {
      throw new Error('store.organizations.findBySignatoryId.error');
    }
    return data.data;
  },

  /*
    Rechercher parmis les organisations
    curl -d "{\"orders\":[{\"fieldName\":\"name\",\"orderBy\":\"ASC\"}],
    \"params\":[{\"paramName\":\"name\",\"paramValue\":\"un nom\"}],
    \"offset\":\"0\",\"limit\":\"30\"}" -H "Authorization: 49d7901a9267e4796e2a878532fa4ec3"
    "http://10.211.55.7:85/index.php?api=organizations&method=search"
   */
  async search(searchParams) {
    const params = { ...searchParams };
    params.limit = searchParams.limit.toString();
    params.offset = searchParams.offset.toString();
    const { data } = await HTTP.post('/organizations/search', params);
    if (data.status !== 'success') {
      throw new Error('store.organizations.search.error');
    }
    return data.data;
  },

  /*
    Ajouter 1 signataire sur n organisations
    curl -d "{\"organizationIds\":[\"DUFFTECH\"],\"user\":{\"userId\":56826}}" -H "Authorization: 0fa1297a92325e9a78a6c0256abecfe6" "https://develop-22.ztp.edocsign.fr/api/V1/account/affectToOrganizations"
  */
  async addSignatoryToOrganisations(payload) {
    const { data } = await HTTP.post('/account/affectToOrganizations', payload);
    if (data.status !== 'success') {
      throw new Error('store.organizations.addSignatoryToOrganisations.error', { organizationIds: payload.group.id });
    }
    return data.data;
  },
  /*
    Supprimer un signataire de n organisations
    curl -d "{\"organizationIds\":[\"DUFFTECH\"],\"user\":{\"userId\":56826}}" -H "Authorization: 0fa1297a92325e9a78a6c0256abecfe6" "https://develop-22.ztp.edocsign.fr/api/V1/account/removeFromOrganizations"
  */
  async removeSignatoryFromOrganisations(payload) {
    const { data } = await HTTP.post('/account/removeFromOrganizations', payload);
    if (data.status !== 'success') {
      throw new Error('store.organizations.removeSignatoryFromOrganisations.error', { organizationIds: payload.organizationIds });
    }
    return data.data;
  },
};
