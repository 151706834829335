export default {
  namespaced: true,
  state: {
    menusAreOpened: false,
    isSidenavOpen: true,
    isMiniVariant: false,
  },
  getters: {
    menusAreOpened: (state) => state.menusAreOpened,
  },
  actions: {
    updateMenusAreOpened(context) {
      context.commit('UPDATE_MENU_ARE_OPENED');
    },
    updateIsMiniVariant(context, value) {
      context.commit('UPDATE_IS_MINI_VARIANT', value);
    },
    updateIsSidenavOpen(context, value) {
      context.commit('UPDATE_IS_SIDENAV_OPEN', value);
    },
  },
  mutations: {
    UPDATE_MENU_ARE_OPENED(state) {
      state.menusAreOpened = !state.menusAreOpened;
    },
    UPDATE_IS_MINI_VARIANT(state, value) {
      state.isMiniVariant = value;
    },
    UPDATE_IS_SIDENAV_OPEN(state, value) {
      state.isSidenavOpen = value;
    },
  },
};
