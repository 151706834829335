import Vue from 'vue';
import {
  ValidationProvider, ValidationObserver, extend, configure,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '../i18n';

const profilePasswordRule = {
  validate(value) {
    // Custom regex for a parapher title
    const is8Long = /^(?=.{8,})/;
    const hasNumber = /^(?=.*[0-9])/;
    const hasCaps = /^(?=.*[A-Z])/;
    const hasSpecialChar = /^(?=.*[!@#$%^&*])/;
    // Check for either of these to return true
    return is8Long.test(value) && hasNumber.test(value) && hasCaps.test(value) && hasSpecialChar.test(value);
  },
};

const phoneRule = {
  validate(value) {
    // Custom regex for a phone number
    const MOBILEREG = /^[+]?[0-9]+$/;

    // Check for either of these to return true
    return MOBILEREG.test(value);
  },
};

const titleRule = {
  validate(value) {
    // Custom regex for a parapher title
    const TITLEREG = /^[0-9a-zàâæçéèêëïîôœùûüÿA-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ.:,_"'()[\]\s\-/]*$/;

    // Check for either of these to return true
    return TITLEREG.test(value);
  },
};

const postalCodeRule = {
  validate(value) {
    // Custom regex for a postal code
    const POSTALCODEREG = /^[a-z0-9\- ]{3,10}$/;

    // Check for either of these to return true
    return POSTALCODEREG.test(value);
  },
};

const validationCodeRule = {
  validate(value) {
    // Custom regex for a postal code
    const VALIDATIONCODEREG = /.{6}$/;

    // Check for either of these to return true
    return VALIDATIONCODEREG.test(value);
  },
};

const socialSignRule = {
  validate(value) {
    let result = true;
    value.forEach((signSocial, mainIndex) => {
      if (signSocial.entityType === 'socialSigner') {
        if (result) {
          result = false;
          for (let i = 0; i < mainIndex; i++) {
            if (signSocial.authorizedUsers.includes(value[i].userId)) result = true;
          }
        }
      }
    });
    return result;
  },
};

const templateSignatoriesRule = {
  validate(value) {
    let result = true;
    value.forEach(sign => {
      if (sign.email === undefined) {
        result = false;
      }
    });
    return result;
  },
};

const templateDocsRule = {
  validate(value) {
    let result = true;
    value.forEach(doc => {
      if (doc.fileContent === undefined) {
        result = false;
      }
    });
    return result;
  },
};

extend('phone', phoneRule);
extend('postal_code', postalCodeRule);
extend('title', titleRule);
extend('validation_code', validationCodeRule);
extend('socialSign', socialSignRule);
extend('templateSignatories', templateSignatoriesRule);
extend('profilePassword', profilePasswordRule);
extend('templateDocs', templateDocsRule);

configure({
  // eslint-disable-next-line no-underscore-dangle
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  },
});
// setInteractionMode('lazy');

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
