import Vue from 'vue';
import VueI18n from 'vue-i18n';
import validationEN from 'vee-validate/dist/locale/en.json';
import validationFR from 'vee-validate/dist/locale/fr.json';
import customValidationEN from './validation/en.json';
import customValidationFR from './validation/fr.json';
import french from './locales/fr.json';
import english from './locales/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language.substring(0, 2),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: {
    fr: {
      ...french.messages,
      validation: Object.assign(validationFR.messages, customValidationFR.messages),
    },
    en: {
      ...english.messages,
      validation: Object.assign(validationEN.messages, customValidationEN.messages),
    },
  },
  dateTimeFormats: {
    fr: french.dateTimeFormats,
    en: english.dateTimeFormats,
  },
});

Vue.$i18n = i18n;
export default i18n;
