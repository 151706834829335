import * as types from './types';
import i18n from '@/i18n/';

export default {
  // to enable or disable loading screen
  startLoading(context) {
    context.commit(types.LOADING_START);
  },
  finishLoading(context) {
    context.commit(types.LOADING_FINISH);
  },
  pushAlert({ commit, state }, payload) {
    const timeout = payload.timeout ? payload.timeout : 5000;

    // translate the first "word" using i18n
    const messageArray = payload.message.split(' ');
    messageArray[0] = i18n.t(messageArray[0]);
    // translate the rest (messages from the back are in english)
    if (messageArray.slice(1).join(' ') === 'email already in use in domain') {
      messageArray.splice(1, messageArray.length - 1, i18n.t('signatory.form.email.error.emailAlreadyUsed'));
    }
    payload.message = messageArray.join(' ');

    let theCommit;
    if (!state.alerts.find((alert) => alert.message === payload.message && alert.type === payload.type)) {
      theCommit = commit(types.PUSH_ALERT, payload);
      if (timeout || (payload.type && payload.type === 'success')) {
        setTimeout(() => {
          commit(types.REMOVE_ALERT, state.alerts[state.alerts.length - 1].id);
        }, timeout);
      }
    }
    return theCommit;
  },
  removeAlert({ commit }, payload) {
    commit(types.REMOVE_ALERT, payload);
  },
  resetAlert({ commit }, payload) {
    return commit(types.RESET_ALERT, payload);
  },
  showSelectDomainDialog({ commit }) {
    commit(types.SHOW_SELECT_DOMAIN);
  },
  closeSelectDomainDialog({ commit }) {
    commit(types.CLOSE_SELECT_DOMAIN);
  },
  setIsMobile({ commit }) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      commit(types.SET_IS_MOBILE, true);
    } else {
      commit(types.SET_IS_MOBILE, false);
    }
  },
  updateResponsive({ commit }) {
    commit(types.UPDATE_RESPONSIVE, document.documentElement.clientWidth);
  },
  setLastBreadcrumb({ commit, rootState }, name) {
    commit(types.SET_LAST_BREADCRUMB, { route: rootState.route, name });
  },
  openDeskRhEmpCreation({ commit }, payload) {
    commit(types.OPEN_EMP_CREATION_DRAWER, payload);
  },
};
