import * as types from './types';
import preferences from '@/utils/preferences';

export default {
  [types.PIN_TO_HOMEPAGE](state, pinToHomePage) {
    if (!Array.isArray(pinToHomePage)) {
      if (!state.pinedToHomePage.find((item) => item === pinToHomePage) && pinToHomePage !== null) {
        state.pinedToHomePage.push(pinToHomePage);
      }
    } else {
      state.pinedToHomePage = [...state.pinedToHomePage, ...pinToHomePage];
    }
    preferences.pinToHomePage(state.pinedToHomePage);
  },

  [types.GET_PIN_TO_HOMEPAGE](state, pinToHomePage) {
    if (pinToHomePage !== null) {
      state.pinedToHomePage = pinToHomePage;
    }
  },

  [types.DELETE_FROM_HOMEPAGE](state, value) {
    state.pinedToHomePage.splice(state.pinedToHomePage.findIndex((item) => item.id === value.id), 1);
    preferences.pinToHomePage(state.pinedToHomePage);
  },

  [types.SET_SIGNWORKFLOWS_VUE_ORDER](state, signworkflowsVueOrder) {
    state.signworkflowsVueOrder = signworkflowsVueOrder;
  },

  [types.SET_SOCIAL_SIGNATORIES_VUE_ORDER](state, socialSignatoriesVueOrder) {
    state.socialSignatoriesVueOrder = socialSignatoriesVueOrder;
  },

};
