export default {
  session: {
    auth: false,
    guest: false,
    token: undefined,
  },
  domains: {
    current: undefined,
    available: undefined,
    isDeskRh: false,
  },
  profile: {
    options: undefined,
    role: undefined,
    isFinalized: false,
  },
  user: undefined,
  usageDatas: undefined,
  isDeskRhUserComingFromStepLogin: false,
  isUserHasEmail: false,
};
