import * as types from './types';
import SignBookTransformer from '../../../transformers/SignBookTransformer';

export default {
  [types.SET_SIGNBOOK](state, value) {
    state.signBook = value;
  },
  [types.SET_SIGNWORKFLOWS](state, data) {
    state.signworkflows = SignBookTransformer.fetchCollection(data.values.signbooks);
    state.nbSignbooks = data.values.nbSignbooks;
    let total;
    if (data.values.nbSignbooks % state.searchParams.limit || parseInt(data.values.nbSignbooks) === 0) {
      total = Math.floor((data.values.nbSignbooks / state.searchParams.limit) + 1);
    } else {
      total = Math.floor((data.values.nbSignbooks / state.searchParams.limit));
    }
    state.pagination = {
      current: ((state.searchParams.offset + state.searchParams.limit) / state.searchParams.limit),
      total,
    };
  },
  [types.SET_STATUS](state, data) {
    if (data.params.params.length) {
      state.status[data.params.params[0].paramValue] = data.values.nbSignbooks;
    }
  },
  [types.RESET_SIGNWORKFLOWS](state) {
    state.signworkflows = {};
    state.nbSignbooks = undefined;
    state.pagination = { current: 1, total: 1 };
  },
  [types.SET_SIGNWORKFLOWS_SEARCH_PARAMS](state, value) {
    state.searchParams = JSON.parse(JSON.stringify(value));
  },
  [types.ADD_SIGNATORY_DESKRH_EMP_CREATION](state, payload) {
    state.signatoryFromDeskRhEmpCreation = payload;
  },
};
