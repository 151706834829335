/* ============
 * FileTypes Mutations
 * ============
 *
 * The mutations available for the File Types module.
 */

import ALL from './types';

export default {
  /**
   * Mutation to update the store with the fetched organizations.
   *
   * @param {Object} state      The current state of the store.
   * @param {Array}  organizations     The fetched organizations.
   * @param {Object} pagination The fetched pagination.
   */
  [ALL](state, { fileTypes }) {
    state.all = fileTypes;
  },
};
