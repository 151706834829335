export default {
  clearStorage() {
    const templatesOnDashboard = localStorage.getItem('templatesOnDashboard');
    localStorage.removeItem('es-session');
    localStorage.removeItem('es-profile');
    localStorage.removeItem('usageDatas');
    localStorage.removeItem('deskRhFeatures');
    localStorage.setItem('templatesOnDashboard', templatesOnDashboard);
  },
};
