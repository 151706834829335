<template>
  <EuiLayout class="eds-guest-layout">
    <AlertManager/>
    <EuiSimpleLoader :isLoading="loading"/>
    <template #sidenav>
      <EuiSidenav open :miniVariant="true">
        <router-link to="/" style="text-decoration: none;">
          <EuiSidenavHeader>
            <div class="flex items-baseline">
              <img src="/logo_blue_collapse.svg" alt="edoc sign logo miniature" width="72" height="72">
            </div>
          </EuiSidenavHeader>
        </router-link>
        <EuiIcon class="eui-u-h-20 eui-u-w-full eui-u-items-center eui-u-justify-center eui-u-mt-auto eui-u-flex eui-u-cursor-pointer" size="medium" color="primary" name="power" @click.native="toLogout"/>
      </EuiSidenav>
    </template>
    <router-view/>
  </EuiLayout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import interceptors from '@/interceptors';
import AlertManager from '@/components/alerts/AlertManager';

export default {
  name: 'GuestLayout',
  mixins: [interceptors],
  components: {
    AlertManager,
  },
  computed: {
    ...mapGetters({
      loading: 'application/loading',
    }),
  },
  methods: {
    ...mapActions({
      logout: 'ModuleEdocSign/auth/logout',
    }),
    toLogout() {
      this.logout();
    },
  },
};
</script>
