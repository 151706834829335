/* ============
 * Getters for the file types module
 * ============
 *
 * The getters that are available on the
 * file types module.
 */

export default {
};
