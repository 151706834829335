export default {
  signatories: [],
  searchParams: {
    orders: [
      {
        fieldName: 'fullname',
        orderBy: 'ASC',
      },
    ],
    offset: 0,
    limit: 20,
  },
  pagination: {
    current: 1,
    total: 1,
  },
};
