import * as types from './types';

export default {
  // change loading state
  [types.LOADING_START](state) {
    state.loading.nb += 1;
    state.loading.isVisible = true;
  },
  [types.LOADING_FINISH](state) {
    state.loading.nb -= 1;
    if (state.loading.nb === 0) {
      state.loading.isVisible = false;
    }
  },
  [types.SET_LAST_BREADCRUMB](state, payload) {
    payload.route.meta.breadcrumbs[payload.route.meta.breadcrumbs.length - 1].name = payload.name;
  },
  [types.SHOW_SELECT_DOMAIN](state) {
    state.showSelectDomainDialog = true;
  },
  [types.CLOSE_SELECT_DOMAIN](state) {
    state.showSelectDomainDialog = false;
  },
  [types.SET_IS_MOBILE](state, value) {
    state.isMobile = value;
  },
  [types.UPDATE_RESPONSIVE](state, docWidth) {
    const [smPoint, mdPoint, lgPoint, xlPoint] = [600, 900, 1200, 1800];
    function between(min, max) {
      return min <= docWidth && docWidth < max;
    }
    Object.assign(state.responsive, {
      xs: docWidth < smPoint,
      sm: docWidth >= smPoint,
      md: docWidth >= mdPoint,
      lg: docWidth >= lgPoint,
      xl: docWidth >= xlPoint,
      only: {
        xs: docWidth < smPoint,
        sm: between(smPoint, mdPoint),
        md: between(mdPoint, lgPoint),
        lg: between(lgPoint, xlPoint),
        xl: docWidth >= xlPoint,
      },
    });
  },
  [types.PUSH_ALERT](state, content) {
    if (!state.blockAlerts) {
      const id = state.alerts.length === 0 ? 0 : state.alerts[state.alerts.length - 1].id + 1;
      content.time = new Date().getTime();
      state.alerts.push({ id, ...content });
    }
  },
  [types.UPDATE_ALERT](state, value) {
    const index = state.alerts.findIndex((x) => x.id === value.id);
    value.time = new Date().getTime();
    state.alerts.splice(index, 1, { ...value });
  },
  [types.REMOVE_ALERT](state, alertId) {
    const index = state.alerts.indexOf((alert) => alert.id === alertId);
    state.alerts.splice(index, 1);
  },
  [types.RESET_ALERT](state) {
    state.alerts = [];
  },
  [types.OPEN_EMP_CREATION_DRAWER](state, payload) {
    state.empCreationDrawer.empCreationDrawerOpen = payload.openDeskRhEmpCreation;
    state.empCreationDrawer.organizationSelected = payload.organization[0];
  },
};
