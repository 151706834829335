import { HTTP } from '../http';

export default {
  login(params) {
    return HTTP.post('/authentication/authenticateAccount', params)
      .then((response) => response.data);
  },
  logout() {
    return HTTP.post('/authentication/closeSession')
      .then((response) => {
        if (response.data.status !== 'success') {
          throw new Error('store.auth.closeSession.error');
        }
        return response.data.status;
      });
  },
  checkOauthCode(oauthCode) {
    return HTTP.post('/authentication/microsoft-authentication', { code: oauthCode })
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  },
  selectDomain(payload) {
    return HTTP.post('/authentication/selectDomain', payload);
  },
  loginWithStepUUID(params) {
    return HTTP.post('/authentication/authenticateAccountWithStepUUID', params)
      .then((response) => response.data);
  },
  getUserInfos() {
    return HTTP.post('/account/retrieveInfos')
      .then((response) => response.data);
  },
  getProfile() {
    return HTTP.post('/account/getProfile')
      .then((response) => response.data);
  },
  getUsageDatas() {
    return HTTP.post('/account/retrieveUsageDatas')
      .then((response) => response.data);
  },
  renewToken(params) {
    return HTTP.post('/authentication/renewStepAccessUUID', params)
      .then((response) => response.data);
  },
};
