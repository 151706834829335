/* ============
 * Templates Mutations
 * ============
 *
 * The mutations available for the templates module.
 */
import { SET_PAGINATION } from './types';

export default {
  /**
   * Mutation to  the store with the current page.
   *
   * @param {Object} state      The current state of the store.
   * @param {Array}  currentPage     The current page.
   */

  [SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
};
