/* ============
 * Organizations State
 * ============
 *
 * The state of the organizations module.
 */

export default {
  pagination: {
    current: 1,
    total: 1,
  },
  searchParams: {
    offset: 0,
    limit: 20,
  },
};
