import * as types from './types';

export default {

  [types.SET_SESSION](state, value) {
    state.session = value;
  },
  [types.SET_PROFILE](state, value) {
    state.profile = value;
  },
  [types.SET_USER_INFOS](state, value) {
    state.user = value;
  },
  [types.SET_USER_HAS_EMAIL](state, value) {
    state.isUserHasEmail = value;
  },
  [types.SET_USAGE_DATAS](state, value) {
    state.usageDatas = value;
  },
  [types.SET_DOMAINS](state, value) {
    state.domains.available = value;
  },
  [types.SET_CURRENT_DOMAIN](state, value) {
    state.domains.current = value;
  },
  [types.SET_IS_DESKRH_DOMAINS](state, value) {
    state.domains.isDeskRh = value;
  },
  [types.SET_IS_DESKRH_USER_COMING_FROM_STEP_LOGIN](state, value) {
    state.isDeskRhUserComingFromStepLogin = value;
  },
};
