<template>
  <div class="alert">
    <template v-for="alert in alerts">
      <DefaultAlert :key="alert.id" :alert="alert" @close="storeRemoveAlert(alert.id)" />
    </template>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import DefaultAlert from './DefaultAlert';

export default {
  name: 'AlertManager',
  components: {
    DefaultAlert,
  },
  data() {
    return {
      showTrialAlert: false,
    };
  },
  computed: {
    ...mapGetters({
      alerts: 'application/alerts',
    }),
  },
  methods: {
    ...mapActions({
      storeRemoveAlert: 'application/removeAlert',
    }),
  },
};
</script>

<style>
  .alert {
    position: fixed;
    z-index: 10000;
    width: 100%;
  }
</style>
