import Axios from 'axios';

export const HTTP = Axios.create({
  baseURL: `${process.env.VUE_APP_API_LOCATION}`,
  timeout: process.env.TIMEOUT,
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

export function setAuthorization(token) {
  HTTP.defaults.headers.common.Authorization = token;
}

export function clearAuthorization() {
  delete HTTP.defaults.headers.common.Authorization;
}

const HTTP_CONF = Axios.create({
  baseURL: '/',
  timeout: process.env.TIMEOUT,
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

export function getRuntimeConfig() {
  return HTTP_CONF.get('./config/runtime.json')
    .then((response) => response.data);
}
