import * as types from './types';
import api from '../../../api/signatory';

export default {
  createSignatory({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.createSignatory(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signatory.create.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        if (!params.muteError) {
          dispatch('application/pushAlert', {
            message: `store.signatory.create.error ${error.data.message}`,
            type: 'error',
          }, { root: true });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  updateSignatory({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.updateSignatory(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signatory.update.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signatory.update.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  deleteSignatory({ dispatch }, searchParams) {
    // loader
    dispatch('application/startLoading', null, { root: true });
    return api.deleteSignatory(searchParams)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signatory.delete.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signatory.delete.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getSignatory({ dispatch }, signatoryId) {
    dispatch('application/startLoading', null, { root: true });
    return api.getSignatory(signatoryId)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data.account);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signatory.get.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  // eslint-disable-next-line no-unused-vars
  getSignatoryLogo({ commit }, searchParams) {
    return api.getSignatory(searchParams)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data.account.logo);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error));
  },
  getSignatories({ commit, dispatch }, searchParams) {
    // loader
    dispatch('application/startLoading', null, { root: true });
    // save search params
    commit(types.SET_SIGNATORY_SEARCH_PARAMS, searchParams);
    // parse such params for request
    const storeParams = JSON.parse(JSON.stringify(searchParams));
    storeParams.offset = searchParams.offset.toString();
    if (storeParams.limit !== undefined) {
      storeParams.limit = searchParams.limit.toString();
    }
    // call api function
    return api.getSignatories(storeParams)
      .then((response) => {
        if (response.status === 'success') {
          commit(types.SET_SIGNATORIES, response.data);
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        commit(types.RESET_SIGNATORIES);
        dispatch('application/pushAlert', {
          message: 'store.signatory.get.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getSocialSignSignatories({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    commit(types.SET_SIGNATORY_SEARCH_PARAMS, searchParams);
    const storeParams = JSON.parse(JSON.stringify(searchParams));
    storeParams.offset = searchParams.offset.toString();
    if (storeParams.limit !== undefined) {
      storeParams.limit = searchParams.limit.toString();
    }

    // call api function
    return api.getSocialSignSignatories(storeParams, searchParams)
      .then((response) => {
        if (response.status === 'success') {
          commit(types.SET_SIGNATORIES, response.data);
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        commit(types.RESET_SIGNATORIES);
        dispatch('application/pushAlert', {
          message: 'store.signatory.get.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  resetSearchParams({ commit }) {
    commit(types.SET_SIGNATORY_SEARCH_PARAMS, {
      orders: [
        {
          fieldName: 'fullname',
          orderBy: 'ASC',
        },
      ],
      offset: 0,
      limit: 20,
    });
  },
  clear({ commit, dispatch }) {
    dispatch('resetSearchParams');
    commit(types.RESET_SIGNATORIES);
  },
};
