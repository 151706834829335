import { HTTP } from '../http';

export default {
  sendCodeBySMS() {
    return HTTP.post('/user-certificate/generate')
      .then((response) => response.data);
  },
  sendCodeByEmail() {
    return HTTP.post('/user-certificate/generateAndSendByMail')
      .then((response) => response.data);
  },
  sendCodeByPhone() {
    return HTTP.post('/user-certificate/generateAndSendByVoice')
      .then((response) => response.data);
  },
};
