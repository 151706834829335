import browserUpdate from 'browser-update';

function addLoadEvent(func) {
  const oldOnLoad = window.onload;
  if (typeof window.onload !== 'function') {
    console.log('window.onload not a function');
    window.onload = func;
  } else {
    console.log('window.onload is a function');
    window.onload = () => {
      if (oldOnLoad) {
        oldOnLoad();
      }
      func();
    };
  }
}
const UnsupportedBrowser = {};
UnsupportedBrowser.install = (Vue, opts) => {
  const ua = navigator.userAgent;
  let tem = null;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  let browser = null;

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    browser = { name: 'IE', version: (tem[1] || '') };
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) { browser = { name: 'Opera', version: tem[1] }; }
  }

  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) { browser = { name: 'Edge', version: tem[1] }; }
  }

  if (browser == null) {
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    browser = { name: M[0], version: +M[1] };
  }

  const isIE = ((browser.name === 'MSIE' || browser.name === 'IE') && browser.version <= 11);

  addLoadEvent(() => {
    if (isIE) {
      const options = {
        text: 'Votre navigateur ({brow_name}) n\'est pas supporté sur cet outil. Veuillez utiliser un navigateur tel que Edge, Chrome ou Mozilla Firefox',
        text_en: 'Your browser ({brow_name}) isn\'t supported on this application. Please use Edge, Firefox or Chrome',
      };
      browserUpdate(options, true);
    }
  });
};

export default UnsupportedBrowser;
