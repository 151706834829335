// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import './validation/vee-validate';
import UUID from 'vue-uuid';
import { sync } from 'vuex-router-sync';
import Default from '@/layouts/Default.vue';
import Guest from '@/layouts/Guest.vue';
import Minimal from '@/layouts/Minimal.vue';
import App from './App';
import i18n from './i18n';
import router from './router';
import store from './store';
import EdocUi from '@silae/edoc-ui';
import UnsupportedBrowser from './plugins/UnsupportedBrowser';
import stonly from './plugins/stonly';

Vue.component('default-layout', Default);
Vue.component('guest-layout', Guest);
Vue.component('minimal-layout', Minimal);

Vue.use(EdocUi);
Vue.use(UUID);
Vue.use(UnsupportedBrowser);
Vue.use(stonly, process.env.VUE_APP_STONLY);
require('@silae/edoc-ui/lib/components/edoc-ui.css');
require('@/assets/scss/main.scss');

sync(store, router);

Vue.config.productionTip = false;

store.dispatch('ModuleEdocSign/auth/check');

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
});

export default { app, router };
