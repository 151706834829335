/* ============
 * Templates State
 * ============
 *
 * The state of the templates module.
 */

export default {
  all: [],
  pagination: {
    totalCount: 0,
    totalPages: 1,
    limit: 10,
  },
};
