import { HTTP } from '../http';

export default {
  async findAll(searchParams) {
    const { data } = await HTTP.post('/models/search', searchParams);
    return data;
  },
  async findById(templateId) {
    const { data } = await HTTP.post('/model/retrieveById', { id: templateId });
    return data;
  },
  async search(searchParams) {
    const { data } = await HTTP.post('/models/search', searchParams);
    return data;
  },
  async create() {
    const { data } = await HTTP.post('');
    return data;
  },
  async update() {
    const { data } = await HTTP.post('');
    return data;
  },
  async destroy(templateId) {
    const { data } = await HTTP.post('/model/delete', { id: templateId });
    return data;
  },
};
