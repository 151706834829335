/* eslint-disable */
export default {
  install: function (Vue, key) {
    window.STONLY_WID = key;

    !function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
      w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
      (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
      4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
        (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
    }(window,document,"script","https://stonly.com/js/widget/v2/");

    const style = document.createElement('style');
    style.id = 'stonly-style';
    style.innerHTML = '.stn-wdgt{display:none}'
    document.head.appendChild(style);
    let identify = undefined;
    let contactForm = {
      email: undefined,
      additionalInfo: {
        product: 'eDocSign',
        token: undefined,
        url: undefined,
        userId: undefined,
        isMainUser: undefined,
      }
    };

    Vue.prototype.$stonly = {
      setIdentify(userId) {
        if (identify !== userId) {
          identify = userId;
          StonlyWidget('identify', userId);
        }
      },
      setEmail(email) {
        contactForm.email = email;
      },
      setToken(token) {
        contactForm.additionalInfo.token = token;
      },
      setUserId(userId) {
        contactForm.additionalInfo.userId = userId;
      },
      setIsMainUser(bool) {
        contactForm.additionalInfo.isMainUser = bool;
      },
      setUrl() {
        contactForm.additionalInfo.url = window.location.href;
        this.sendData();
      },
      sendData() {
        StonlyWidget('sendData', { contactForm });
      },
      enable() {
        style.innerHTML = '.stn-wdgt{display:block}'
      },
      disable() {
        style.innerHTML = '.stn-wdgt{display:none}'
      },
    };
  },
};
