export default {
  dontShowAddSignatureGuideAgain() {
    localStorage.setItem('dontShowAddSignatureGuideAgain', 'true');
  },
  getDontShowAddSignatureGuideAgain() {
    return JSON.parse(localStorage.getItem('dontShowAddSignatureGuideAgain'));
  },
  pinToHomePage(templateId) {
    localStorage.setItem('templatesOnDashboard', JSON.stringify(templateId));
  },
  getPinToHomePage() {
    return JSON.parse(localStorage.getItem('templatesOnDashboard'));
  },
  setSignworkflowsVueOrder(signworkflowsVueOrder) {
    localStorage.setItem('signworkflowsVueOrder', JSON.stringify(signworkflowsVueOrder));
  },
  getSignworkflowsVueOrder() {
    return JSON.parse(localStorage.getItem('signworkflowsVueOrder'));
  },
  setSocialSignatoriesVueOrder(socialSignatoriesVueOrder) {
    localStorage.setItem('socialSignatoriesVueOrder', JSON.stringify(socialSignatoriesVueOrder));
  },
  getSocialSignatoriesVueOrder() {
    return JSON.parse(localStorage.getItem('socialSignatoriesVueOrder'));
  },
  updateSortedHeaders(userHeaders, headers, action) {
    const sortHeaders = [];
    const headerKeys = headers.map(e => e.field);
    const added = headerKeys.filter(x => !userHeaders.includes(x));
    const deleted = userHeaders.filter(x => !headerKeys.includes(x));

    // add field in sortHeaders only if field always exists in headers
    userHeaders.forEach((field) => {
      if (!deleted.includes(field)) {
        sortHeaders.push(headers.find(header => header.field === field));
      }
    });

    // add field in sortHeaders if field has been added in headers and is not found in userHeaders (example: new column)
    if (added.length) {
      added.forEach((item) => {
        sortHeaders.push(headers.find(header => header.field === item));
      });
    }

    // if differences have been found between headers fields and userHeaders fields, set new order to profile store
    if (added.length || deleted.length) {
      action(sortHeaders.map(e => e.field));
    }
    return sortHeaders;
  },
};
