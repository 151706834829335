/* eslint-disable no-param-reassign */
/* ============
 * SignBook Transformer
 * ============
 *
 * The transformer for a SignBook.
 */

import Transformer from './BaseTransformer';

class SignBookTransformer extends Transformer {
  /**
   * Method used to transform a fetched signBook.
   *
   * @returns {Object} signBook An SignBook object.
   */
  static fetch(signBook) {
    const { steps } = signBook;
    const alertedRecipients = [];
    const alertDates = [];
    steps.forEach((item) => {
      item.limitSignatureDate = item.limitSignatureDate.substr(0, 10);
      // eslint-disable-next-line no-nested-ternary
      item.type = item.signerType === 'external' ? 'externalSignatory' : item.signerType === 'internal' ? 'internalUser' : item.signerType === 'social' ? 'socialSignatory' : undefined;
      item.fullname = item.signerName;
      delete item.signerName;
      if (item.alerts && item.alerts.length > 0) {
        alertedRecipients.push(item.alerts[item.alerts.length - 1].recipient);
        alertDates.push(new Date(item.alerts[item.alerts.length - 1].date.replace(' ', 'T')));
      }
      delete item.signerType;
      item.executionDate = item.executionDate ? item.executionDate = item.executionDate.substr(0, 10) : null;
    });
    return {
      name: signBook.name,
      endingDate: signBook.endingDate.replace(' ', 'T'),
      creationDate: signBook.creationDate.replace(' ', 'T'),
      notificationEmail: signBook.notificationEmail,
      globalMessage: signBook.globalMessage,
      allowDecline: signBook.allowDecline === '1',
      allowFirstThenSimultaneousSteps: signBook.allowFirstThenSimultaneousSteps === '1',
      allowSimultaneousSteps: signBook.allowSimultaneousSteps === '1',
      sendToCustomerOnEdocFactory: signBook.sendToCustomerOnEdocFactory === '1',
      nbSteps: parseInt(signBook.nbSteps, 10),
      nbStepsRealized: parseInt(signBook.nbStepsRealized, 10),
      nextStepDate: signBook.nextStepDate ? signBook.nextStepDate.replace(' ', 'T') : undefined,
      finalizedDate: signBook.finalizedDate ? signBook.finalizedDate.replace(' ', 'T') : undefined,
      nextSignerName: signBook.nextSignerName,
      alertConfiguration: {
        frequency: parseInt(signBook.alertConfiguration.frequency, 10),
        intervall: parseInt(signBook.alertConfiguration.intervall, 10) / (60 * 60 * 24),
      },
      owner: signBook.owner,
      documents: signBook.documents,
      steps,
      refutalReason: signBook.refutalReason,
      alertedRecipients: alertedRecipients.join(', '),
      lastAlertDate: alertDates.length > 0 ? new Date(Math.max(...alertDates)) : undefined,
      recipientOrganizations: signBook.organizationsArchival,
      thirdParties: signBook.thirdParties,
      signBookId: signBook.signBookId,
    };
  }
}

export default SignBookTransformer;
