/* eslint-disable no-dupe-class-members */
import cloneDeep from 'lodash.clonedeep';
import {FileUtil} from '../utils/file';
import {PIXEL_RATIO} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export class Signbook {
  static isTrue(boolean) {
    return (boolean === true || boolean === 'true' || boolean === '1' || boolean === 1);
  }
  static #getDate(duration) {
    let date = new Date();
    date = new Date(date.setHours(23, 59, 59));
    date = new Date(date.setDate(date.getDate() + parseInt(duration)));
    return this.#formatDate(date);
  }
  static #formatDate(date) {
    return new Date(date).toISOString().split('T')[0];
  }
  static #getDocuments(documents) {
    for (let index = 0; index < documents.length; index++) {
      documents[index].type = {
        typeCode: documents[index].typeCode,
        typeDescription: documents[index].typeDescription,
      };
      delete documents[index].typeCode;
      delete documents[index].typeDescription;
    }
    return documents;
  }
  static #getSignOrder(allowSimultaneousSteps, allowFirstThenSimultaneousSteps) {
    if (!this.isTrue(allowSimultaneousSteps) && !this.isTrue(allowFirstThenSimultaneousSteps)) return 'sequentially';
    else if (this.isTrue(allowFirstThenSimultaneousSteps)) return 'hybrid';
    else if (this.isTrue(allowSimultaneousSteps)) return 'simultaneously';
  }
  static #getSignatoryZones(signatory, zones) {
    const signatures = zones.filter(zone => zone.zone.user.userId === signatory.userId && zone.zone.zoneType === 'signature');
    const paraphs = zones.filter(zone => zone.zone.user.userId === signatory.userId && zone.zone.zoneType === 'paraph');
    const texts = zones.filter(zone => zone.zone.user.userId === signatory.userId && zone.zone.zoneType === 'textfield');
    return { signatures, paraphs, texts };
  }
  static #getZones(index, step, documents) {
    const zones = [];
    const positions = JSON.parse(step.signatureParams);
    if (positions.length > 0) {
      for (let j = 0; j < positions.length; j++) {
        const position = positions[j];
        position.viewport = undefined;
        position.docId = position.docId.split('_').pop();
        delete position.type;
        zones.push({
          id: Math.random().toString(36).slice(4),
          zone: {
            zoneType: 'signature',
            user: { fullname: step.fullname },
            pagePoint: undefined,
            position,
          },
        });
      }
    } else if (step.captiveZones) {
      for (let i = 0; i < step.captiveZones.length; i++) {
        const zone = step.captiveZones[i];
        if (zone.zoneType === 'signature') {
          for (let j = 0; j < zone.positions.length; j++) {
            const position = zone.positions[j];
            if (position.positionType === 'semi-automatic') {
              position.x = undefined;
              position.y = undefined;
            }
            position.docIndex = documents.findIndex(doc => {
              return doc.id === position.docId;
            });
            zones.push({
              id: Math.random().toString(36).slice(4),
              signerOnlyValidate: step.signerOnlyValidate === '1',
              isHidden: position.isInvisibleSignature,
              zone: {
                zoneType: zone.zoneType,
                user: index,
                pagePoint: undefined,
                position,
              },
            });
          }
        } else {
          zone.position.docIndex = documents.findIndex(doc => {
            return doc.id === zone.position.docId;
          });
          if (zone.zoneType === 'paraph') {
            zone.position.page = 1;
          }
          zones.push({
            id: Math.random().toString(36).slice(4),
            zone: {
              zoneType: zone.zoneType,
              ...((zone.zoneType === 'textfield' && zone.fontSize) && { fontSize: zone.fontSize }),
              ...((zone.zoneType === 'textfield' && zone.name) && { name: zone.name }),
              user: index,
              pagePoint: undefined,
              position: zone.position,
            },
          });
        }
      }
    }
    return zones;
  }
  static #convertToViewportPosition(viewport, x, y, width, height) {
    const bottomLeftPoint = viewport.convertToViewportPoint(x, y + height);
    const topRightPoint = viewport.convertToViewportPoint(x + width, y);
    return {
      x: bottomLeftPoint[0],
      y: bottomLeftPoint[1],
      height: topRightPoint[1] - bottomLeftPoint[1],
      width: topRightPoint[0] - bottomLeftPoint[0],
    };
  }
  static #convertToPdfPosition(viewport, x, y, width, height) {
    const bottomLeftPoint = viewport.convertToPdfPoint(x * PIXEL_RATIO, y * PIXEL_RATIO + height * PIXEL_RATIO);
    const topRightPoint = viewport.convertToPdfPoint(x * PIXEL_RATIO + width * PIXEL_RATIO, y * PIXEL_RATIO);
    return {
      x: Math.round(Math.abs(bottomLeftPoint[0])),
      y: Math.round(Math.abs(bottomLeftPoint[1])),
      height: Math.round(Math.abs(topRightPoint[1] - bottomLeftPoint[1])),
      width: Math.round(Math.abs(topRightPoint[0] - bottomLeftPoint[0])),
    };
  }
  static async send(signBook) {
    return new Promise((resolve) => {
      const newSignBook = cloneDeep(signBook);
      newSignBook.endingDate = new Date(newSignBook.endingDate).setHours(23, 59, 59).toString().slice(0, -3);
      newSignBook.globalMessage = newSignBook.globalMessage ? newSignBook.globalMessage.replace(/(\r\n|\r|\n)/g, '<br/>') : '';
      delete newSignBook.signatureOrdering;

      for (let i = 0; i < newSignBook.signatories.length; i++) {
        const signatory = newSignBook.signatories[i];
        const docIdsToSign = [];
        const captiveZones = [];
        const signatureZones = {
          zoneType: 'signature',
          format: null,
          positions: [],
        };
        for (let j = 0; j < signatory.signatures.length; j++) {
          if (!docIdsToSign.includes(signatory.signatures[j].zone.position.docId)) {
            docIdsToSign.push(signatory.signatures[j].zone.position.docId);
          }

          const viewport = cloneDeep(signatory.signatures[j].zone.position.viewport);
          if (viewport !== undefined) {
            viewport.transform[5] = viewport.height;
            const { x, y, width, height } = this.#convertToPdfPosition(
              viewport,
              signatory.signatures[j].zone.position.x,
              signatory.signatures[j].zone.position.y,
              signatory.signatures[j].zone.position.width,
              signatory.signatures[j].zone.position.height);
            signatory.signatures[j].zone.position.x = x;
            signatory.signatures[j].zone.position.y = y;
            signatory.signatures[j].zone.position.height = height;
            signatory.signatures[j].zone.position.width = width;
            delete signatory.signatures[j].zone.position.viewport;
            delete signatory.signatures[j].zone.pagePoint;
            delete signatory.signatures[j].zone.user;
            delete signatory.signatures[j].zone.position.docIndex;
            delete signatory.signatures[j].id;
          }
          signatory.signatures[j].zone.position.isInvisibleSignature = signatory.signatures[j].isHidden;
          signatureZones.positions.push(signatory.signatures[j].zone.position);
        }
        for (let j = 0; j < signatory.paraphs.length; j++) {
          if (!docIdsToSign.includes(signatory.paraphs[j].zone.position.docId)) {
            docIdsToSign.push(signatory.paraphs[j].zone.position.docId);
          }

          delete signatory.paraphs[j].zone.position.viewport;
          delete signatory.paraphs[j].zone.page;
          delete signatory.paraphs[j].zone.pagePoint;
          delete signatory.paraphs[j].zone.user;
          delete signatory.paraphs[j].zone.drag;
          delete signatory.paraphs[j].zone.scale;
          delete signatory.paraphs[j].zone.position.docIndex;
          delete signatory.paraphs[j].id;
          captiveZones.push(signatory.paraphs[j].zone);
        }
        for (let j = 0; j < signatory.texts.length; j++) {
          if (!docIdsToSign.includes(signatory.texts[j].zone.position.docId)) {
            docIdsToSign.push(signatory.texts[j].zone.position.docId);
          }
          const viewport = cloneDeep(signatory.texts[j].zone.position.viewport);
          if (viewport !== undefined) {
            viewport.transform[5] = viewport.height;

            const { x, y, width, height } = this.#convertToPdfPosition(
              viewport,
              signatory.texts[j].zone.position.x,
              signatory.texts[j].zone.position.y,
              signatory.texts[j].zone.position.width,
              signatory.texts[j].zone.position.height);

            signatory.texts[j].zone.position.x = x;
            signatory.texts[j].zone.position.y = y;
            signatory.texts[j].zone.position.height = height;
            signatory.texts[j].zone.position.width = width;
          }

          delete signatory.texts[j].zone.position.viewport;
          delete signatory.texts[j].zone.pagePoint;
          delete signatory.texts[j].zone.user;
          delete signatory.texts[j].zone.position.docIndex;
          delete signatory.texts[j].id;
          captiveZones.push(signatory.texts[j].zone);
        }
        if (signatureZones.positions.length > 0) {
          captiveZones.push(signatureZones);
        }
        const step = {
          id: i,
          comment: signatory.comment || '',
          date: signatory.date ? new Date(signatory.date).setHours(23, 59, 59).toString().slice(0, -3) : newSignBook.endingDate,
          docIdsToSign,
          signerOnlyValidate: signatory.signerOnlyValidate,
          userId: signatory.userId,
          archiveToEdocPerso: signatory.archiveToEdocPerso,
          captiveZones,
        };
        newSignBook.steps.push(step);
      }
      const promises = [];
      for (let i = 0; i < newSignBook.documents.length; i += 1) {
        if (newSignBook.documents[i].type && newSignBook.documents[i].type.typeCode && newSignBook.documents[i].type.typeDescription) {
          newSignBook.documents[i].typeCode = newSignBook.documents[i].type.typeCode;
          newSignBook.documents[i].typeDescription = newSignBook.documents[i].type.typeDescription;
        }
        delete newSignBook.documents[i].type;

        promises.push(FileUtil.getBase64(newSignBook.documents[i].fileContent)
          .then((fileContent) => {
            newSignBook.documents[i].fileContent = fileContent;
          }));
      }
      delete newSignBook.signatories;
      delete newSignBook.signsList;
      Promise.all(promises).then(() => {
        resolve(newSignBook);
      });
    });
  }
  static fetch(template) {
    const signatories = [];
    let signsList = [];
    for (let i = 0; i < template.steps.length; i++) {
      const item = template.steps[i];
      const zones = this.#getZones(i, item, template.documents);
      signsList = signsList.concat(zones);
      const signatory = {
        type: item.userType === 'external' ? 'externalSignatory' : item.userType === 'internal' ? 'internalUser' : item.userType === 'social' ? 'socialSignatory' : undefined,
        signerOnlyValidate: item.signerOnlyValidate === '1',
      };
      Object.assign(signatory, this.#getSignatoryZones(signatory, zones));

      if (item.duration && parseInt(item.duration) > 0) {
        signatory.date = this.#getDate(item.duration);
        if (i === 0) {
          item.date = signatory.date;
        } else {
          const date = new Date(template.steps[i - 1].date);
          item.date = this.#formatDate(new Date(date.setDate(date.getDate() + parseInt(item.duration))));
        }
      }
      signatories.push(signatory);
    }

    return {
      name: template.name,
      endingDate: this.#getDate(template.duration),
      notificationEmail: template.notificationEmail ? template.notificationEmail : undefined,
      allowDecline: template.allowDecline === '1',
      globalMessage: template.globalMessage ? template.globalMessage.replaceAll('<br/>', '\r\n') : '',
      allowFirstThenSimultaneousSteps: template.allowFirstThenSimultaneousSteps === '1',
      allowSimultaneousSteps: template.allowSimultaneousSteps === '1',
      alertConfiguration: {
        frequency: parseInt(template.alertFrequency),
        intervall: parseInt(template.alertIntervall) / (60 * 60 * 24),
      },
      signatureOrdering: this.#getSignOrder(template.allowSimultaneousSteps, template.allowFirstThenSimultaneousSteps),
      signatories,
      documents: this.#getDocuments(template.documents),
      signsList,
      recipientOrganizations: [],
      thirdPartiesEmails: template.thirdParties ? template.thirdParties : [],
    };
  }
}
