import { mapActions } from 'vuex';
import { HTTP } from '@/http';

export default {
  methods: {
    ...mapActions({
      pushAlert: 'application/pushAlert',
      resetAlert: 'application/resetAlert',
      logout: 'ModuleEdocSign/auth/logout',
    }),
    setInterceptors() {
      const responseHandler = (response) => {
        if (response.data.data && response.data.data.message &&
            (response.data.data.message === 'Auth token not valid' ||
            response.data.data.message === 'session not valid') &&
            this.$store.state.ModuleEdocSign.auth.session.auth) {
          this.resetAlert()
            .then(() => {
              if (response.config.url !== '/authentication/closeSession') {
                this.logout();
              }
              this.pushAlert({
                message: 'store.login.sessionExpired',
                type: 'error',
              });
            });
          this.$router.push({ name: 'login' });
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      };
      const errorHandler = (error) => Promise.reject(error);
      HTTP.interceptors.response.use(responseHandler, errorHandler);
    },
  },
};
