/* ============
 * Organizations Mutations
 * ============
 *
 * The mutations available for the organizations module.
 */

import { SET_PAGINATION } from './types';

export default {
  /**
   * Mutation to the store with the current pagination values.
   *
   * @param {Object} state      The current state of the store.
   * @param {Object} pagination The fetched pagination.
   */
  [SET_PAGINATION](state, pagination) {
    Object.keys(pagination).forEach((key) => {
      state.pagination[key] = pagination[key];
    });
  },
};
