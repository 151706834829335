export default {
  isMobile: false,
  showSelectDomainDialog: false,
  responsive: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    only: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
    },
  },
  loading: {
    nb: 0,
    isVisible: false,
  },
  alerts: [],
  empCreationDrawer: {
    empCreationDrawerOpen: false,
    organizationSelected: {},
  },
};
