/* ============
 * Templates Mutations Types
 * ============
 *
 * The mutations available for the templates module.
 */

export const SET_PAGINATION = 'SET_PAGINATION';

export default {
  SET_PAGINATION,
};
