<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import interceptors from '@/interceptors';

const defaultLayout = 'default';
const guestLayout = 'guest';

export default {
  mixins: [interceptors],
  watch: {
    $route() {
      this.$stonly.setUrl();
    },
  },
  computed: {
    ...mapState({
      responsive: (state) => state.application.responsive,
    }),
    isGuest() {
      return this.$route.meta.guest && this.$store.state.ModuleEdocSign.auth.session.guest;
    },
    isDeskRhUser() {
      return this.$store.state.ModuleEdocSign.auth.isDeskRhUserComingFromStepLogin;
    },
    layout() {
      return this.$route.name ? `${this.$route.meta.layout ? this.$route.meta.layout : (this.isDeskRhUser || this.isGuest) ? guestLayout : defaultLayout}-layout` : '';
    },
  },
  methods: {
    ...mapActions({
      updateResponsive: 'application/updateResponsive',
      setIsMobile: 'application/setIsMobile',
    }),
    onResize() {
      this.updateResponsive();
      document.querySelector(':root').style
        .setProperty('--vh', `${window.innerHeight / 100}px`);
    },
  },
  mounted() {
    this.onResize();
    this.setIsMobile();
    this.setInterceptors();
    this.$stonly.enable();
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
    this.$stonly.disable();
  },
};
</script>
