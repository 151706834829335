/* ============
 * Templates Actions
 * ============
 *
 * The actions available for the Templates module.
 */

import TemplateApi from '../../../api/template';
import { Signbook } from '../../../transformers/Signbook';
import * as types from '../templates/types';

export default {
  async findAll({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await TemplateApi.findAll(searchParams);
      if (response.status !== 'success') {
        throw new Error('store.templates.findAll.error');
      }
      const pagination = {
        limit: searchParams.limit,
        totalCount: response.data.nbModels,
        totalPages: Math.ceil(response.data.nbModels / searchParams.limit),
      };
      commit(types.SET_PAGINATION, pagination);
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error.message);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async findById({ dispatch }, templateId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await TemplateApi.findById(templateId);
      if (response.status !== 'success') {
        throw new Error('store.templates.findById.error');
      }
      const res = Signbook.fetch(response.data);
      return Promise.resolve(res);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error.message);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async search({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await TemplateApi.search(searchParams);
      if (response.status !== 'success') {
        throw new Error('store.templates.search.error');
      }
      const pagination = {
        limit: searchParams.limit,
        totalCount: response.data.nbModels,
        totalPages: Math.ceil(response.data.nbModels / searchParams.limit),
      };
      commit(types.SET_PAGINATION, pagination);
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error.message);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async create({ dispatch }, template) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await TemplateApi.create(template);
      if (response.status !== 'success') {
        throw new Error('store.templates.create.error');
      } else {
        dispatch('application/pushAlert', {
          message: 'store.templates.create.success',
          type: 'success',
        }, { root: true });
      }
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error.message);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async update({ dispatch }, template) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await TemplateApi.update(template);
      if (response.status !== 'success') {
        throw new Error('store.templates.update.error');
      } else {
        dispatch('application/pushAlert', {
          message: 'store.templates.update.success',
          type: 'success',
        }, { root: true });
      }
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error.message);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async destroy({ dispatch }, templateId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await TemplateApi.destroy(templateId);
      if (response.status !== 'success') {
        throw new Error('store.templates.destroy.error');
      }
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error.message);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
};
