<template>
  <div class="eds-minimal-layout">
    <AlertManager/>
    <EuiSimpleLoader :isLoading="loading"/>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertManager from '@/components/alerts/AlertManager';

export default {
  name: 'MinimalLayout',
  components: {
    AlertManager,
  },
  computed: {
    ...mapGetters({
      loading: 'application/loading',
    }),
  },
};
</script>
