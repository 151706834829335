import * as types from './types';
import FileTypesApi from '../../../api/fileTypes';

export default {
  async search({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await FileTypesApi.search(searchParams).then((response) => {
        commit(types.ALL, response);
        return response;
      });
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
};
