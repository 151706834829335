<template>
  <EuiAlert :color="alert.type"
            @click:close="$emit('close')" :closable="alert.type !== 'success' && alert.timeout === undefined">
    <template>
      <div class="eui-u-flex eui-u-items-center" v-html="$t(sanitizedMessage)"></div>
      <div v-if="alert.error !== undefined">{{ alert.error }}</div>
    </template>
    <template #actions>
      <EuiButton :id="alert.button.text + alert.id"
                 @click="alertButtonClicked(alert)" v-if="alert.button">{{ alert.button.text }}
      </EuiButton>
    </template>
  </EuiAlert>
</template>

<script>
import DOMpurify from 'dompurify';

export default {
  name: 'DefaultAlert',
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sanitizedMessage() {
      return DOMpurify.sanitize(this.alert.message);
    },
  },
  methods: {
    alertButtonClicked(alert) {
      alert.button.action();
    },
  },
};
</script>
