export default {
  session: (state) => state.session,
  options: (state) => state.profile.options,
  can: (state) => (option, noOptionReturnValue = false) => {
    if (state.profile.options && state.profile.options[option]) {
      return state.profile.options[option].value === '1';
    }
    return noOptionReturnValue;
  },
  contain: (state) => (option, string, noOptionReturnValue = false) => {
    if (state.profile.options && state.profile.options[option]) {
      const strings = state.profile.options[option].value.split(';');
      return strings.includes(string);
    }
    return noOptionReturnValue;
  },
  onlyManualPositionModeEnabled: (state) => {
    if (state.profile.options && state.profile.options['parapherCreationFormPositionTypesAvailable']) {
      const modsEnabled = state.profile.options['parapherCreationFormPositionTypesAvailable'].value.split(';');

      return !!(modsEnabled.length === 1 && modsEnabled.includes('manual'));
    }
  },
  role: (state) => state.profile.role,
  profile: (state) => state.profile,
  user: (state) => state.user,
  isUserHasEmail: (state) => state.isUserHasEmail,
  isManager: (state) => state.profile.role === 'manager',
  usageDatas: (state) => state.usageDatas,
  isDomainSelectable: (state) => (state.domains.available ? state.domains.available.length > 1 : false),
  isDeskRhDomain: (state) => state.domains.isDeskRh,
};
