import * as types from './types';
import api from '../../../api/signworkflows';
import SignBookTransformer from '../../../transformers/SignBookTransformer';
import { Signbook } from '../../../transformers/Signbook';
import downloadjs from 'downloadjs';

export default {
  async createSignworkflow({dispatch}, params) {
    dispatch('application/startLoading', null, {root: true});
    const parapher = await Signbook.send(params);
    return api.createSignworkflow(parapher)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.create.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.create.error',
          error: error.data.message,
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  async getSignBookById({ commit, dispatch }, signBookId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const { status, data } = await api.getSignBookById({ signBookId });
      if (status === 'success') {
        commit(types.SET_SIGNBOOK, SignBookTransformer.fetch(data));
        return Promise.resolve(data);
      }
      return Promise.reject(data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  getFolderFromSafe({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.getFolderFromSafe(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.getFolder.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getDocumentFromSafe({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.getDocumentFromSafe(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.getDocument.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  archiveSignworkflow({ dispatch }, params) {
    // loader
    dispatch('application/startLoading', null, { root: true });
    return api.archiveSignworkflow(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.archive.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.archive.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  deleteSignworkflow({ dispatch }, params) {
    // loader
    dispatch('application/startLoading', null, { root: true });
    return api.deleteSignworkflow(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.delete.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.delete.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  sendSignature({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.sendSignature(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.sign.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        if (error.data.message === 'signature password is wrong') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.sign.wrongPwd',
            type: 'error',
          }, { root: true });
        } else {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.sign.error',
            type: 'error',
          }, { root: true });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  sendUSBSignature({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.sendUSBSignature(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.signUsb.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.signUsb.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  refuseSignature({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.refuseSignature(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.refuse.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.refuse.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  sendReminder({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.sendReminder(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch('application/pushAlert', {
            message: 'store.signworkflows.reminder.success',
            type: 'success',
          }, { root: true });
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.reminder.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  updateStepDeadline({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.updateStepDeadline(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error))
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  updateStepSignatory({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.updateStepSignatory(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error))
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  updateParaphAlert({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.updateParaphAlert(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error))
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  deleteStep({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.deleteStep(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error))
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getDocBinaryContent({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.getDocBinaryContent(params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getDocContent({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.getDocContent(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error))
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getDocContentWithUUID({ commit, dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return api.getDocContentWithUUID(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  downloadDoc({ dispatch }, [id, filename]) {
    dispatch('application/startLoading', null, { root: true });
    return api.downloadDoc({ id })
      .then((response) => {
        downloadjs(response, `${filename}.pdf`);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.downloadDoc.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getArchive({ dispatch }, [signBookId, filename]) {
    dispatch('application/startLoading', null, { root: true });
    return api.getArchive({ signBookId })
      .then((response) => {
        downloadjs(response, `${filename}.zip`);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.getArchive.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  getSignworkflows({ commit, dispatch }, params) {
    // loader
    dispatch('application/startLoading', null, { root: true });
    // save search params
    if (params.limit !== 1) {
      commit(types.SET_SIGNWORKFLOWS_SEARCH_PARAMS, params);
    }
    // parse such params for request
    const storeParams = JSON.parse(JSON.stringify(params));
    storeParams.offset = params.offset.toString();
    storeParams.limit = params.limit.toString();
    // // remove dates params depending on status
    if ((storeParams.allowToFilterByDate === undefined || storeParams.allowToFilterByDate === false) && storeParams.params && storeParams.params.length > 0 && (storeParams.params[0].paramValue === 'waiting-for-others' ||
        storeParams.params[0].paramValue === 'waiting-for-action' ||
        storeParams.params[0].paramValue === 'expire-soon' ||
        storeParams.params[0].paramValue === 'expired')) {
      const indexDateStart = storeParams.params.findIndex((x) => x.paramName === 'creationDateStart');
      if (indexDateStart >= 0) storeParams.params.splice(indexDateStart, 1);
      const indexDateEnd = storeParams.params.findIndex((x) => x.paramName === 'creationDateEnd');
      if (indexDateEnd >= 0) storeParams.params.splice(indexDateEnd, 1);
    }
    // call api function
    return api.getSignworkflows(storeParams)
      .then((response) => {
        if (response.status === 'success') {
          if (params.limit !== 1) {
            commit(types.SET_SIGNWORKFLOWS, { values: response.data, params: storeParams });
          } else {
            commit(types.SET_STATUS, { values: response.data, params: storeParams });
          }
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        commit(types.RESET_SIGNWORKFLOWS);
        dispatch('application/pushAlert', {
          message: 'store.signworkflows.get.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  // eslint-disable-next-line no-unused-vars
  getSignworkflowsCount({ commit }, params) {
    return api.getSignworkflows(params)
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => Promise.reject(error));
  },
  getByThirdPartyId({ dispatch }) {
    // loader
    dispatch('application/startLoading', null, { root: true });
    // call api function
    return api.getSignworkflowForThirdParty()
      .then((response) => {
        if (response.status === 'success') {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        dispatch('application/pushAlert', {
          message: 'store.signworkflow.get.error',
          type: 'error',
        }, { root: true });
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  async searchFieldOnPDF({ dispatch }, value) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const { status, data } = await api.searchFieldOnPDF(value);
      if (status === 'success') {
        return Promise.resolve(data);
      }
      return Promise.reject(data);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: 'store.signworkflows.getCoord.error',
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },

  resetSearchParams({ commit }) {
    commit(types.SET_SIGNWORKFLOWS_SEARCH_PARAMS, {
      orders: [
        {
          fieldName: 'creationDate',
          orderBy: 'DESC',
        },
      ],
      params: [
        { paramName: 'status', paramValue: 'inbox' },
      ],
      offset: 0,
      limit: 20,
    });
  },
  clear({ commit, dispatch }) {
    dispatch('resetSearchParams');
    commit(types.RESET_SIGNWORKFLOWS);
  },
  async transformToTemplate({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await api.transformToTemplate(params);
      if (response.status !== 'success') {
        throw new Error('templates.transformToTemplate.error');
      }
      return response.data;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  addSignatoryFromDeskRhEmpCreation({ commit }, payload) {
    commit(types.ADD_SIGNATORY_DESKRH_EMP_CREATION, payload);
  },
};
