import { HTTP } from '../http';

export default {
  createSignatory(params) {
    return HTTP.post('/account/create', params)
      .then((response) => response.data);
  },
  updateSignatory(params) {
    return HTTP.post('/account/update', params)
      .then((response) => response.data);
  },
  deleteSignatory(searchParams) {
    return HTTP.post('/account/delete', searchParams)
      .then((response) => response.data);
  },
  getSignatory(searchParams) {
    return HTTP.post('/account/retrieveById', searchParams)
      .then((response) => response.data);
  },
  getSignatories(searchParams) {
    return HTTP.post('/accounts/search', searchParams)
      .then((response) => response.data);
  },
  getSocialSignSignatories(searchParams) {
    return HTTP.post('/accounts/searchInternalUsers', searchParams)
      .then((response) => response.data);
  },

};
