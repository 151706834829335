export const SET_SESSION = 'SET_SESSION';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_USER_INFOS = 'SET_USER_INFOS';
export const SET_USER_HAS_EMAIL = 'SET_USER_HAS_EMAIL';
export const SET_USAGE_DATAS = 'SET_USAGE_DATAS';
export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_CURRENT_DOMAIN = 'SET_CURRENT_DOMAIN';
export const SET_IS_DESKRH_DOMAINS = 'SET_IS_DESKRH_DOMAINS';
export const SET_IS_DESKRH_USER_COMING_FROM_STEP_LOGIN = 'SET_IS_DESKRH_USER_COMING_FROM_STEP_LOGIN';
export const SET_DESKRH_USER_FEATURES = 'SET_DESKRH_USER_FEATURES';
