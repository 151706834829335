/* ============
 * Users Actions
 * ============
 *
 * The actions available for the Users module.
 */

import UserApi from '../../../api/user';
import * as types from './types';

export default {
  async findAll({ commit, dispatch }) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await UserApi.findAll();
      if (response.status !== 'success') {
        throw new Error('store.users.findAll.error');
      }
      const pagination = {
        current: 1,
        total: Math.ceil(response.data.nbAccounts / 20),
      };
      commit(types.SET_PAGINATION, pagination);
      return response.data;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async findById({ dispatch }, userId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await UserApi.findById(userId);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async search({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await UserApi.search(searchParams);
      if (response.status !== 'success') {
        throw new Error('store.users.search.error');
      }
      const pagination = {
        current: (searchParams.offset + searchParams.limit) / searchParams.limit,
        total: Math.ceil((response.data.nbAccounts / searchParams.limit)),
      };
      commit(types.SET_PAGINATION, pagination);
      return response.data;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async updateInternalUserCredentials({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await UserApi.updateInternalUserCredentials(params).then((response) => {
        dispatch('application/pushAlert', {
          message: 'store.users.updateInternalUserCredentials.success',
          type: 'success',
        }, { root: true });
        return response;
      });
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async searchSocialSigners({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await UserApi.searchSocialSigners(searchParams);
      if (response.status !== 'success') {
        throw new Error('store.users.search.error');
      }
      const pagination = {
        current: (searchParams.offset + searchParams.limit) / searchParams.limit,
        total: Math.ceil((response.data.nbAccounts / searchParams.limit)),
      };
      commit(types.SET_PAGINATION, pagination);
      return response.data;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async create({ dispatch }, user) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await UserApi.create(user).then((response) => {
        dispatch('application/pushAlert', {
          message: 'store.users.create.success',
          type: 'success',
        }, { root: true });
        return response;
      });
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async update({ dispatch }, user) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await UserApi.update(user);
      if (response.status !== 'success') {
        // Condition liée aux limitations du back EDS
        if (response.data.message.includes('Given email address is incorrect')) {
          dispatch('application/pushAlert', {
            message: 'store.users.update.error.mail',
            type: 'error',
          }, {root: true});
        } else throw new Error('store.users.update.error');
      } else {
        dispatch('application/pushAlert', {
          message: 'store.users.update.success',
          type: 'success',
        }, {root: true});
      }
      return response;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      });
      return Promise.reject(error);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async destroy({ dispatch }, userId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await UserApi.destroy(userId);
      if (response.status !== 'success') {
        throw new Error('store.users.destroy.error');
      } else {
        dispatch('application/pushAlert', {
          message: 'store.users.destroy.success',
          type: 'success',
        }, { root: true });
      }
      return response.data;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      return Promise.reject(error);
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
};
