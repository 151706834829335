export class FileUtil {
  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.replace('data:application/pdf;base64,', ''));
      };
      reader.onerror = () => reject(reader.result);
    });
  }
}
