import preferences from '../../../utils/preferences';
import * as types from './types';

export default {
  pinToHomePage({ commit, dispatch }, value) {
    dispatch('application/pushAlert', {
      message: 'store.preferences.pinTemplates.add.success',
      type: 'success',
    }, { root: true });
    commit(types.PIN_TO_HOMEPAGE, value);
  },

  getPinToHomePage(context) {
    context.commit(types.GET_PIN_TO_HOMEPAGE, preferences.getPinToHomePage());
  },

  deletePinFromHomePage({ commit, dispatch }, value) {
    dispatch('application/pushAlert', {
      message: 'store.preferences.pinTemplates.delete.success',
      type: 'success',
    }, { root: true });
    commit(types.DELETE_FROM_HOMEPAGE, value);
  },

  setSignworkflowsVueOrder(context, signworkflowsVueOrder) {
    context.commit(types.SET_SIGNWORKFLOWS_VUE_ORDER, signworkflowsVueOrder);
    preferences.setSignworkflowsVueOrder(signworkflowsVueOrder);
  },

  getSignworkflowsVueOrder(context) {
    context.commit(types.SET_SIGNWORKFLOWS_VUE_ORDER, preferences.getSignworkflowsVueOrder());
  },

  setSocialSignatoriesVueOrder(context, socialSignatoriesVueOrder) {
    context.commit(types.SET_SOCIAL_SIGNATORIES_VUE_ORDER, socialSignatoriesVueOrder);
    preferences.setSocialSignatoriesVueOrder(socialSignatoriesVueOrder);
  },

  getSocialSignatoriesVueOrder(context) {
    context.commit(types.SET_SOCIAL_SIGNATORIES_VUE_ORDER, preferences.getSocialSignatoriesVueOrder());
  },
};
