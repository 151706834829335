/* ============
 * Groups Actions
 * ============
 *
 * The actions available for the Groups module.
 */

import GroupApi from '../../../api/group';
import * as types from './types';

export default {
  async findAll({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      const response = await GroupApi.findAll(searchParams);
      const pagination = {
        limit: searchParams.limit,
        totalCount: response.nbGroups,
        totalPages: Math.ceil(response.nbGroups / searchParams.limit),
      };
      commit(types.SET_PAGINATION, pagination);
      return response;
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async findById({ commit, dispatch }, groupId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.findById(groupId);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async search({ commit, dispatch }, searchParams) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.search(searchParams).then((response) => {
        const pagination = {
          limit: searchParams.limit,
          totalCount: response.nbGroups,
          current: (searchParams.offset + searchParams.limit) / searchParams.limit,
          totalPages: Math.ceil(response.nbGroups / searchParams.limit),
        };
        commit(types.SET_PAGINATION, pagination);
        return response;
      });
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async create({ commit, dispatch }, group) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.create(group).then((response) => {
        dispatch('application/pushAlert', {
          message: 'store.groups.create.success',
          type: 'success',
        }, { root: true });
        return response;
      });
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async update({ commit, dispatch }, group) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.update(group).then(() =>
        dispatch('application/pushAlert', {
          message: 'store.groups.update.success',
          type: 'success',
        }, { root: true }));
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async addUserToGroup({ commit, dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.addUserToGroup(payload);
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async deleteUserFromGroup({ commit, dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.deleteUserFromGroup(payload.groupId, payload.userId).then(() =>
        dispatch('application/pushAlert', {
          message: 'store.groups.deleteUserFromGroup.success',
          type: 'success',
        }, { root: true }));
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
  async destroy({ commit, dispatch }, groupId) {
    dispatch('application/startLoading', null, { root: true });
    try {
      return await GroupApi.destroy(groupId).then(() =>
        dispatch('application/pushAlert', {
          message: 'store.groups.destroy.success',
          type: 'success',
        }, { root: true }));
    } catch (error) {
      dispatch('application/pushAlert', {
        message: error.message,
        type: 'error',
      }, { root: true });
      throw error;
    } finally {
      dispatch('application/finishLoading', null, { root: true });
    }
  },
};
