import api from '../../../api/certificate';

export default {
  namespaced: true,
  state: {},
  actions: {
    sendCodeBySMS({ dispatch }) {
      dispatch('application/startLoading', null, { root: true });
      return api.sendCodeBySMS()
        .then((response) => {
          if (response.status === 'success') {
            dispatch('application/pushAlert', {
              message: 'store.certificate.sms.success',
              type: 'success',
            }, { root: true });
            return Promise.resolve(response.data);
          }
          return Promise.reject(response);
        })
        .catch((error) => {
          dispatch('application/pushAlert', {
            message: 'store.certificate.sms.error',
            type: 'error',
          }, { root: true });
          return Promise.reject(error);
        })
        .finally(() => {
          dispatch('application/finishLoading', null, { root: true });
        });
    },
    sendCodeByEmail({ dispatch }) {
      dispatch('application/startLoading', null, { root: true });
      return api.sendCodeByEmail()
        .then((response) => {
          if (response.status === 'success') {
            dispatch('application/pushAlert', {
              message: 'store.certificate.email.success',
              type: 'success',
            }, { root: true });
            return Promise.resolve(response.data);
          }
          return Promise.reject(response);
        })
        .catch((error) => {
          dispatch('application/pushAlert', {
            message: 'store.certificate.email.error',
            type: 'error',
          }, { root: true });
          return Promise.reject(error);
        })
        .finally(() => {
          dispatch('application/finishLoading', null, { root: true });
        });
    },
    sendCodeByPhone({ dispatch }) {
      dispatch('application/startLoading', null, { root: true });
      return api.sendCodeByPhone()
        .then((response) => {
          if (response.status === 'success') {
            dispatch('application/pushAlert', {
              message: 'store.certificate.phone.success',
              type: 'success',
            }, { root: true });
            return Promise.resolve(response.data);
          }
          return Promise.reject(response);
        })
        .catch((error) => {
          dispatch('application/pushAlert', {
            message: 'store.certificate.phone.error',
            type: 'error',
          }, { root: true });
          return Promise.reject(error);
        })
        .finally(() => {
          dispatch('application/finishLoading', null, { root: true });
        });
    },
  },
};
