export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_LAST_BREADCRUMB = 'SET_LAST_BREADCRUMB';
export const UPDATE_RESPONSIVE = 'UPDATE_RESPONSIVE';
export const SHOW_SELECT_DOMAIN = 'SHOW_SELECT_DOMAIN';
export const CLOSE_SELECT_DOMAIN = 'CLOSE_SELECT_DOMAIN';
export const PUSH_ALERT = 'PUSH_ALERT';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const RESET_ALERT = 'RESET_ALERT';
export const OPEN_EMP_CREATION_DRAWER = 'OPEN_EMP_CREATION_DRAWER';
