import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import i18n from '../i18n';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/pages/dashboard'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
        ],
      },
    },
    {
      path: '/paraphers/create/',
      component: () => import('@/pages/paraphers/create/index'),
      props: true,
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.createSignbook') },
        ],
      },
      children: [
        {
          path: '',
          redirect: 'add-parameters',
        },
        {
          path: 'add-parameters',
          name: 'paraphers.create.addParameters',
          component: () => import('@/pages/paraphers/create/AddParameters'),
          meta: {
            step: 1,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.createSignbook') },
            ],
          },
        },
        {
          path: 'add-signatories',
          name: 'paraphers.create.addSignatories',
          component: () => import('@/pages/paraphers/create/AddSignatories'),
          meta: {
            step: 2,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.createSignbook') },
            ],
          },
        },
        {
          path: 'add-documents',
          name: 'paraphers.create.addDocuments',
          component: () => import('@/pages/paraphers/create/AddDocuments'),
          meta: {
            step: 3,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.createSignbook') },
            ],
          },
        },
        {
          path: 'add-signatures',
          name: 'paraphers.create.addSignatures',
          component: () => import('@/pages/paraphers/create/AddSignatures'),
          meta: {
            step: 4,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.createSignbook') },
            ],
          },
        },
        {
          path: 'success',
          name: 'paraphers.create.success',
          component: () => import('@/pages/paraphers/create/Success'),
          meta: {
            step: 5,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.createSignbook') },
            ],
          },
        },
      ],
    },
    {
      path: '/signworkflows/:tab',
      name: 'signworkflows',
      component: () => import('@/pages/signworkflows/signworkflows'),
      props: true,
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: '' },
        ],
      },
    },
    {
      path: '/signworkflows/:signBookId/edit',
      name: 'signworkflow.edit',
      component: () => import('@/pages/signworkflows/edit'),
      props: true,
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.signbookDetail') },
        ],
      },
    },
    {
      path: '/guestSignworkflows/details',
      name: 'detailsGuestSignworkflow',
      component: () => import('@/pages/signworkflows/guestDetails'),
      props: true,
      meta: {
        auth: true,
        guest: true,
      },
    },
    {
      path: '/thirdPartySignworkflow',
      name: 'thirdPartySignworkflow',
      component: () => import('@/pages/signworkflows/thirdPartySignworkflow'),
      props: true,
      meta: {
        auth: true,
        guest: true,
      },
    },
    {
      path: '/guestSignworkflows/:signBookId/sign',
      name: 'guestSignworkflows.sign',
      component: () => import('@/pages/signworkflows/sign'),
      props: true,
      meta: {
        auth: true,
        guest: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.signSignbook') },
        ],
      },
    },
    {
      path: '/guestSignworkflows',
      name: 'guestSignworkflows',
      component: () => import('@/pages/signworkflows/guestSignworkflows'),
      props: true,
      meta: {
        auth: true,
        guest: true,
      },
    },
    {
      path: '/guestSignworkflows/access',
      name: 'guestSignworkflows.access',
      component: () => import('@/pages/signworkflows/guestSignworkflows'),
      props: true,
      meta: {
        auth: true,
        guest: true,
      },
    },
    {
      path: '/templates/:templateId/use',
      component: () => import('@/pages/paraphers/create/index'),
      props: true,
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.allTemplates'), link: 'templates.all' },
          { name: i18n.t('navigation.useTemplate') },
        ],
      },
      children: [
        {
          path: '',
          redirect: 'add-parameters',
        },
        {
          path: 'add-parameters',
          name: 'templates.use.addParameters',
          component: () => import('@/pages/paraphers/create/AddParameters'),
          meta: {
            step: 1,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.allTemplates'), link: 'templates.all' },
              { name: i18n.t('navigation.useTemplate') },
            ],
          },
        },
        {
          path: 'add-signatories',
          name: 'templates.use.addSignatories',
          component: () => import('@/pages/paraphers/create/AddSignatories'),
          meta: {
            step: 2,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.allTemplates'), link: 'templates.all' },
              { name: i18n.t('navigation.useTemplate') },
            ],
          },
        },
        {
          path: 'add-documents',
          name: 'templates.use.addDocuments',
          component: () => import('@/pages/paraphers/create/AddDocuments'),
          meta: {
            step: 3,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.allTemplates'), link: 'templates.all' },
              { name: i18n.t('navigation.useTemplate') },
            ],
          },
        },
        {
          path: 'add-signatures',
          name: 'templates.use.addSignatures',
          component: () => import('@/pages/paraphers/create/AddSignatures'),
          meta: {
            step: 4,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.allTemplates'), link: 'templates.all' },
              { name: i18n.t('navigation.useTemplate') },
            ],
          },
        },
        {
          path: 'success',
          name: 'templates.use.success',
          component: () => import('@/pages/paraphers/create/Success'),
          meta: {
            step: 5,
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.allTemplates'), link: 'templates.all' },
              { name: i18n.t('navigation.useTemplate') },
            ],
          },
        },
      ],
    },
    {
      path: '/templates',
      component: () => import('@/pages/templates/index.vue'),
      children: [
        {
          path: '',
          name: 'templates.all',
          component: () => import('@/pages/templates/all.vue'),
          meta: {
            auth: true,
            breadcrumbs: [
              { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
              { name: i18n.t('navigation.allTemplates') },
            ],
          },
        },
        /* {
          path: 'mine',
          name: 'templates.index.mine',
        }, */
      ],
      meta: {
        auth: true,
      },
    },

    {
      path: '/settings',
      redirect: '/settings/users',
    },
    {
      path: '/settings/users',
      name: 'settings.users.index',
      component: () => import('@/pages/settings/users/index.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.users') },
        ],
      },
    },
    {
      path: '/settings/users/create',
      name: 'settings.users.create',
      component: () => import('@/pages/settings/users/create.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.users'), link: 'settings.users.index' },
          { name: i18n.t('navigation.createUser') },
        ],
      },
    },
    {
      path: '/settings/users/:userId',
      name: 'settings.users.edit',
      props: true,
      component: () => import('@/pages/settings/users/edit.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.users'), link: 'settings.users.index' },
          { name: '' },
        ],
      },
    },
    {
      path: '/settings/groups/create',
      name: 'settings.groups.create',
      component: () => import('@/pages/settings/groups/create.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.userGroups'), link: 'settings.groups.index' },
          { name: i18n.t('navigation.createGroup') },
        ],
      },
    },
    {
      path: '/settings/groups/:groupId',
      name: 'settings.groups.edit',
      component: () => import('@/pages/settings/groups/edit.vue'),
      props: true,
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.userGroups'), link: 'settings.groups.index' },
          { name: '' },
        ],
      },
    },
    {
      path: '/settings/groups',
      name: 'settings.groups.index',
      component: () => import('@/pages/settings/groups/index.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.userGroups') },
        ],
      },
    },
    {
      path: '/signatories',
      redirect: '/signatories/internal',
    },
    {
      path: '/signatories/external',
      name: 'signatories.external.index',
      component: () => import('@/pages/signatories/external/index.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.externalSignatories') },
        ],
      },
    },
    {
      path: '/signatories/social',
      name: 'signatories.social.index',
      component: () => import('@/pages/signatories/social/index.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.socialSignatories') },
        ],
      },
    },
    {
      path: '/signatories/internal',
      name: 'signatories.internal.index',
      component: () => import('@/pages/signatories/internal/index.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.internalSignatories') },
        ],
      },
    },
    {
      path: '/signatories/social/create',
      name: 'signatories.social.create',
      component: () => import('@/pages/signatories/social/create'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.socialSignatories'), link: 'signatories.social.index' },
          { name: i18n.t('navigation.createSocialSignatory') },
        ],
      },
    },
    {
      path: '/signatories/social/:userId',
      name: 'signatories.social.edit',
      props: true,
      component: () => import('@/pages/signatories/social/edit.vue'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.socialSignatories'), link: 'signatories.social.index' },
          { signatoryName: route => route.params.name },
        ],
      },
    },
    {
      path: '/signatories/external/create',
      name: 'signatories.external.create',
      component: () => import('@/pages/signatories/external/create'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.externalSignatories'), link: 'signatories.external.index' },
          { name: i18n.t('navigation.createExternalSignatory') },
        ],
      },
    },
    {
      path: '/signatories/external/:signId',
      name: 'signatories.external.edit',
      props: true,
      component: () => import('@/pages/signatories/external/edit'),
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.externalSignatories'), link: 'signatories.external.index' },
          { name: '' },
        ],
      },
    },
    {
      path: '/accessToken/:stepUuid/renew',
      name: 'accessToken.renew',
      component: () => import('@/pages/accessTokenRenewal'),
      props: true,
    },
    {
      path: '/:action/:stepUuid/error',
      name: 'error',
      meta: { layout: 'minimal' },
      component: () => import('@/pages/errors/error'),
      props: true,
      async beforeEnter(to, from, next) {
        if (!to.params.errorCode) {
          if (to.params.action === 'sign') next({ name: 'stepSignLogin', params: { stepUuid: to.params.stepUuid } });
          else next({ name: 'stepAccessLogin', params: { stepUuid: to.params.stepUuid } });
        } else next();
      },
    },
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'minimal' },
      component: () => import('@/pages/login'),
    },
    {
      path: '/login/:sessionId',
      name: 'login.session',
      props: true,
      async beforeEnter(to, from, next) {
        try {
          const session = {
            token: to.params.sessionId,
            guest: false,
            auth: true,
          };
          const response = await store.dispatch('ModuleEdocSign/auth/loginWithSession', session);
          if (response && store.state.ModuleEdocSign.auth.session.auth) {
            next({ name: 'dashboard' });
          }
        } catch (e) {
          next({ path: 'login' });
        }
      },
    },
    {
      path: '/autologin/:authResponse',
      name: 'autologin',
      props: true,
      async beforeEnter(to, from, next) {
        try {
          const response = await store.dispatch('ModuleEdocSign/auth/loginWithAuthResponse', to.params.authResponse);
          if (response && store.state.ModuleEdocSign.auth.session.auth) {
            document.location.href = '/';
          }
        } catch (e) {
          next({ path: 'login' });
        }
      },
    },
    {
      path: '/autologin-stepuuid/:authResponse',
      name: 'autologin',
      props: true,
      async beforeEnter(to, from, next) {
        try {
          const response = await store.dispatch('ModuleEdocSign/auth/stepLoginWithAuthResponse', to.params.authResponse);
          if (response && store.state.ModuleEdocSign.auth.session.auth) {
            next({ name: 'guestSignworkflows.sign', params: { signBookId: response.data.relatedSignbookId } });
          }
        } catch (e) {
          if (e.message === 'access token not valid anymore') {
            next({ name: 'error', params: { stepUuid: to.params.stepUuid, errorCode: e.data.code } });
          } else {
            next({ path: 'login' });
          }
        }
      },
    },
    {
      path: '/badLogin',
      name: 'badLogin',
      async beforeEnter(to, from, next) {
        store.dispatch('ModuleEdocSign/auth/simulateLoginFailed');
        next({ path: 'login' });
      },
    },
    {
      path: '/gsign/:id/:login/:password',
      name: 'gSignLogin-withpassword',
      meta: { layout: 'minimal' },
      component: () => import('@/pages/login'),
      props: true,
    },
    {
      path: '/gsign/:id',
      name: 'gSignLogin',
      meta: { layout: 'minimal' },
      component: () => import('@/pages/login'),
      props: true,
    },
    {
      path: '/gaccess/:id/:login/:password',
      name: 'gAccessLogin',
      meta: { layout: 'minimal' },
      component: () => import('@/pages/login'),
      props: true,
    },
    {
      path: '/sign/:stepUuid',
      name: 'stepSignLogin',
      props: true,
      async beforeEnter(to, from, next) {
        store.dispatch('ModuleEdocSign/auth/setDeskRhUserComingFromStepLogin', true);
        try {
          const response = await store.dispatch('ModuleEdocSign/auth/stepLogin', { uuid: to.params.stepUuid });
          if (response) {
            const userInfos = await store.dispatch('ModuleEdocSign/auth/getUserInfos');
            if (!userInfos.data.isDeskRhDomain) {
              store.dispatch('ModuleEdocSign/auth/setDeskRhUserComingFromStepLogin', false);
            }
            if (store.state.ModuleEdocSign.auth.session.auth) {
              next({ name: 'guestSignworkflows.sign', params: { signBookId: response.data.relatedSignbookId } });
            }
          }
        } catch (e) {
          next({ name: 'error', params: { stepUuid: to.params.stepUuid, errorCode: e.data.code ? e.data.code : '0', action: 'sign' } });
        }
      },
    },
    {
      path: '/access/:stepUuid',
      name: 'stepAccessLogin',
      props: true,
      async beforeEnter(to, from, next) {
        try {
          const response = await store.dispatch('ModuleEdocSign/auth/stepLogin', { uuid: to.params.stepUuid });
          if (response) {
            if (!store.state.ModuleEdocSign.auth.session.guest && store.state.ModuleEdocSign.auth.session.auth) {
              next({ name: 'signworkflow.edit', params: { signBookId: response.data.relatedSignbookId, fromTab: 'sent' } });
            } else {
              next({ name: 'guestSignworkflows.access', params: { signBookId: response.data.relatedSignbookId } });
            }
          }
        } catch (e) {
          next({ name: 'error', params: { stepUuid: to.params.stepUuid, errorCode: e.data.code, action: 'access' } });
        }
      },
    },
    {
      path: '/tp-access/:thirdPartyAccessId',
      name: 'thirdParty-login',
      props: true,
      async beforeEnter(to, from, next) {
        try {
          const response = await store.dispatch('ModuleEdocSign/auth/thirdPartyLogin', { uuid: to.params.thirdPartyAccessId });
          if (response.token) {
            next({ path: '/thirdPartySignworkflow' });
          }
        } catch (e) {
          next({ path: 'login' });
        }
      },
    },
    {
      path: '/profile',
      name: 'profile.index',
      component: () => import('@/pages/profile'),
      props: true,
      meta: {
        auth: true,
        breadcrumbs: [
          { name: i18n.t('navigation.home'), link: 'dashboard', icon: 'home' },
          { name: i18n.t('navigation.profile') },
        ],
      },
    },
    {
      path: '/auto-login-ms',
      async beforeEnter(to, from, next) {
        if (to.query.code && to.query.code.length) {
          store.dispatch('ModuleEdocSign/auth/checkOauthCode', to.query.code)
            .then((response) => {
              console.log('auto-login-ms response', response);
              if (response.status === 200 && response.data.status !== 'error') {
                const session = {
                  token: response.headers['set-authorization'],
                  guest: false,
                  auth: true,
                };
                store.dispatch('ModuleEdocSign/auth/loginWithSession', session).then((response) => {
                  if (response && store.state.ModuleEdocSign.auth.session.auth) {
                    next({ name: 'dashboard' });
                  } else {
                    redirectToLogin();
                  }
                });
              } else {
                redirectToLogin({ errorOAuth: 200 });
              }
            })
            .catch((error) => {
              if (error.response.status) {
                redirectToLogin({ errorOAuth: error.response.status });
              } else {
                redirectToLogin({ errorOAuth: 405 });
              }
            });
        }
      },
    },
    /* {
      path: '/templates/create',
      component: () => import('@/pages/templates/create.vue'),
      children: [
        {
          path: 'add-documents-and-recipients',
          name: 'templates.create.addDocumentsAndRecipients',
          meta: {
            auth: true,
          },
        },
        {
          path: 'add-fields',
          name: 'templates.create.addFields',
          meta: {
            auth: true,
          },
        },
        {
          path: 'finish-and-save',
          name: 'templates.create.finishAndSave',
          meta: {
            auth: true,
          },
        },
      ],
      meta: {
        auth: true,
      },
    }, */
    {
      path: '*',
      redirect: '/dashboard',
    },
  ],
});

router.afterEach((to, from) => {
  const mainLayout = document.getElementsByClassName('eui-o-layout__main')[0];
  if (mainLayout) {
    mainLayout.scrollTop = 0;
  }
  if (to.matched.some((m) => m.meta.auth) && store.state.ModuleEdocSign.auth.session.auth && !store.state.ModuleEdocSign.auth.session.guest) {
    if (store.state.ModuleEdocSign.auth.user === undefined) {
      store.dispatch('ModuleEdocSign/auth/getUserInfos');
    }
  }
});

function redirectToLogin(params = null) {
  router.push({ name: 'Login', params });
}

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && store.state.ModuleEdocSign.auth.session.auth && to.params.sessionId !== undefined) {
    store.dispatch('ModuleEdocSign/auth/clearAll');
    store.dispatch('ModuleEdocSign/auth/callApiLogout').finally(() => {
      next();
    });
  } else if (to.name === 'login' && store.state.ModuleEdocSign.auth.session.auth) {
    if (store.state.ModuleEdocSign.auth.session.guest) {
      next({
        name: 'guestSignworkflows',
      });
    } else {
      next({
        name: 'dashboard',
      });
    }
  } else if (to.name === 'gSignLogin' || to.name === 'gAccessLogin' || to.name === 'stepSignLogin' || to.name === 'stepAccessLogin' || to.name === 'thirdParty-login') {
    store.dispatch('ModuleEdocSign/auth/clearAll');
    store.dispatch('ModuleEdocSign/auth/callApiLogout').finally(() => {
      next();
    });
  } else if (to.matched.some((m) => m.meta.auth) && !store.state.ModuleEdocSign.auth.session.auth) {
    next({
      name: 'login',
    });
  } else if (to.matched.some((m) => m.meta.guest) && !store.state.ModuleEdocSign.auth.session.guest && store.state.ModuleEdocSign.auth.session.auth) {
    if (to.name === 'guestSignworkflows.sign' || to.name === 'guestSignworkflows.access') {
      next();
    } else {
      next({
        name: 'dashboard',
      });
    }
  } else if (!(to.matched.some((m) => m.meta.guest)) && store.state.ModuleEdocSign.auth.session.guest) {
    next({
      name: 'guestSignworkflows',
    });
  } else {
    next();
  }
});

export default router;
