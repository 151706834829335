import { HTTP } from '../http';

export default {
  createSignworkflow(params) {
    return HTTP.post('/signbook/create', params)
      .then((response) => response.data);
  },
  /* createSignworkflow(params) {
    return HTTP.post('/signbook/createTest', params, {
      responseType: 'arraybuffer',
      headers: {Accept: 'application/octet-stream'},
    }).then((response) => response.data);
  }, */
  getSignBookById(params) {
    return HTTP.post('/signbook/load', params)
      .then((response) => response.data);
  },
  getFolderFromSafe(params) {
    return HTTP.post('/folders/retrieveChildren', params)
      .then((response) => response.data);
  },
  getDocumentFromSafe(params) {
    return HTTP.post('/documents/search', params)
      .then((response) => response.data);
  },
  archiveSignworkflow(params) {
    return HTTP.post('/signbook/archive', params)
      .then((response) => response.data);
  },
  deleteSignworkflow(params) {
    return HTTP.post('/signbook/delete', params)
      .then((response) => response.data);
  },
  sendSignature(params) {
    return HTTP.post('/signbook/sign', params)
      .then((response) => response.data);
  },
  transformToTemplate(params) {
    return HTTP.post('/signbook/transformToModel', params)
      .then((response) => response.data);
  },
  sendUSBSignature(params) {
    return HTTP.post('/signbook/signWithUserOwnCertificate', params)
      .then((response) => response.data);
  },
  refuseSignature(params) {
    return HTTP.post('/signbook/refuteSignature', params)
      .then((response) => response.data);
  },
  sendReminder(params) {
    return HTTP.post('/signbook-step/sendReminder', params)
      .then((response) => response.data);
  },
  updateStepDeadline(params) {
    return HTTP.post('/signbook-step/update', params)
      .then((response) => response.data);
  },
  updateStepSignatory(params) {
    return HTTP.post('/signbook-step/modifySigner', params)
      .then((response) => response.data);
  },
  updateParaphAlert(params) {
    return HTTP.post('/signbook/updateAlertConfiguration', params)
      .then((response) => response.data);
  },
  deleteStep(params) {
    return HTTP.post('/signbook-step/delete', params)
      .then((response) => response.data);
  },
  getDocBinaryContent(params) {
    return HTTP.post('/signbook-document/getBinaryContent', params)
      .then((response) => response.data);
  },
  getDocContent(params) {
    return HTTP.post('/signbook-document/getContent', params)
      .then((response) => response.data);
  },
  getDocContentWithUUID(params) {
    return HTTP.post('/signbook-document/getContentWithUUID', params)
      .then(response => response.data);
  },
  downloadDoc(params) {
    return HTTP.post('/signbook-document/getBinaryContent', params, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    }).then((response) => response.data);
  },
  getArchive(params) {
    return HTTP.post('/signbook/getBinaryArchive', params, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    }).then((response) => response.data);
  },
  getSignworkflows(searchParams) {
    return HTTP.post('/signbooks/search', searchParams)
      .then((response) => response.data);
  },
  searchFieldOnPDF(value) {
    return HTTP.post('/signbook-document/getWordCoordinates', value)
      .then((response) => response.data);
  },
  getSignworkflowForThirdParty() {
    return HTTP.post('/signbook/retrieveWithThirdPartyId')
      .then((response) => response.data);
  },
};
