/* ============
 * FileTypes State
 * ============
 *
 * The state of the FileTypes module.
 */

export default {
  all: [],
};
