import Vue from 'vue';
import Vuex from 'vuex';

import application from './modules/application';
import auth from './modules/auth';
import certificate from './modules/certificate';
import fileTypes from './modules/file-types';
import organizations from './modules/organizations';
import signatory from './modules/signatory';
import templates from './modules/templates';
import settingsUsers from './modules/settings-users';
import settingsGroups from './modules/settings-groups';
import parapheurs from './modules/parapheurs';
import ui from './modules/ui';
import preferences from './modules/preferences';
// require('es6-promise').polyfill();

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    ModuleEdocSign: {
      namespaced: true,
      modules: {
        fileTypes,
        parapheurs,
        signatory,
        settingsUsers,
        settingsGroups,
        auth,
        templates,
        organizations,
        certificate,
        preferences,
        ui,
      },
    },
    application,
  },
  /**
   * If strict mode should be enabled.
   */
  strict: debug,
});

export default store;
