/* ============
 * Organizations Mutations
 * ============
 *
 * The mutations available for the organizations module.
 */

export const SET_PAGINATION = 'SET_PAGINATION';

export default {
  SET_PAGINATION,
};
