export default {
  signBook: {},
  signworkflows: {},
  nbSignbooks: undefined,
  searchParams: {
    orders: [
      {
        fieldName: 'creationDate',
        orderBy: 'DESC',
      },
    ],
    params: [
      { paramName: 'status', paramValue: 'inbox' },
    ],
    offset: 0,
    limit: 20,
  },
  status: {},
  pagination: {
    current: 1,
    total: 1,
  },
  signatoryFromDeskRhEmpCreation: undefined,
};
