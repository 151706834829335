/* ============
 * Users settings State
 * ============
 *
 * The state of the settings users module.
 */

export default {
  pagination: {
    current: 1,
    total: 1,
  },
};
