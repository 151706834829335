/* ============
 * Users Settings  Mutations Types
 * ============
 *
 * The mutations available for the SettingsUsers  module.
 */

export const SET_PAGINATION = 'SET_PAGINATION';

export default {
  SET_PAGINATION,
};
