import { HTTP } from '../http';

export default {
  async findAll() {
    const { data } = await HTTP.post('/accounts/searchInternalUsers',
      { orders: [{ fieldName: 'lastname', orderBy: 'ASC' }], offset: 0, limit: 20 });
    return data;
  },
  async findById(userId) {
    const { data } = await HTTP.post('/account/retrieveById', { userId });
    if (data.status !== 'success') {
      throw new Error('store.users.findById.error');
    }
    return data.data;
  },
  async search(searchParams) {
    const params = { ...searchParams };
    params.limit = searchParams.limit.toString();
    params.offset = searchParams.offset.toString();
    const { data } = await HTTP.post('/accounts/searchInternalUsers', params);
    return data;
  },
  async updateInternalUserCredentials(params) {
    const { data } = await HTTP.post('/account/updateInternalUserCredentials', params);
    if (data.status !== 'success') {
      throw new Error(`store.users.updateInternalUserCredentials.error: ${data.data.message}`);
    }
    return data.data;
  },
  async searchSocialSigners(searchParams) {
    const params = { ...searchParams };
    params.limit = searchParams.limit.toString();
    params.offset = searchParams.offset.toString();
    const { data } = await HTTP.post('/accounts/searchSocialSigners', params);
    return data;
  },
  async create(item) {
    const { data } = await HTTP.post('/account/createInternalUser', item);
    if (data.status !== 'success') {
      throw new Error(`store.users.create.error ${data.data.message}`);
    }
    return data.data;
  },
  async update(item) {
    const { data } = await HTTP.post('/account/update', item);
    return data;
  },
  async destroy(userId) {
    const { data } = await HTTP.post('/account/delete', { userId });
    return data;
  },
};
