/* ============
 * Getters for the organizations module
 * ============
 *
 * The getters that are available on the
 * organizations module.
 */

export default {
  searchParams: (state) => state.searchParams,
  pagination: (state) => state.pagination,
};
