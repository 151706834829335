import * as types from './types';

export default {
  [types.SET_SIGNATORIES](state, values) {
    state.signatories = values.accounts;
    let total;
    if (values.nbAccounts % state.searchParams.limit || parseInt(values.nbAccounts) === 0) {
      total = Math.floor((values.nbAccounts / state.searchParams.limit) + 1);
    } else {
      total = Math.floor((values.nbAccounts / state.searchParams.limit));
    }
    state.pagination = {
      current: ((state.searchParams.offset + state.searchParams.limit) / state.searchParams.limit),
      total,
    };
  },
  [types.RESET_SIGNATORIES](state) {
    state.signatories = {};
    state.pagination = { current: 1, total: 1 };
  },
  [types.SET_SIGNATORY_SEARCH_PARAMS](state, value) {
    Object.keys(value).forEach((key) => {
      state.searchParams[key] = value[key];
    });
  },
};
