<template>
  <div class="eds-default-layout">
    <SelectDomainDialog v-model="showSelectDomainDialog"/>
    <EuiSimpleLoader :isLoading="loading"/>
    <EuiLayout>
      <AlertManager/>
      <template #header>
        <EuiHeader>
          <template #slot-right>
            <div class="flex items-center">
              <span class="mr-4">{{ $t('header.connectedWith') }}{{ domainName }}</span>
              <EuiDropdownMenu>
                <template #anchor>
                  <EuiButton iconOnly size="large">
                    <EuiIcon name="person"/>
                  </EuiButton>
                </template>
                <EuiList>
                  <EuiListItem :button="true" @click="$router.push({ name: 'profile.index' })">
                    {{ $t('button.profile') }}
                  </EuiListItem>
                  <EuiListItem v-if="isDomainSelectable" :button="true" @click="selectDomain">
                    {{ $t('header.userOptions.role') }}
                  </EuiListItem>
                  <EuiListItem :button="true" @click="toLogout">
                    {{ $t('button.logout') }}
                  </EuiListItem>
                </EuiList>
              </EuiDropdownMenu>
            </div>
          </template>
        </EuiHeader>
        <EuiHeaderMobile @menu="isSidenavOpen = !isSidenavOpen">
          <EuiDropdownMenu>
            <template #anchor>
              <EuiButton iconOnly size="large">
                <EuiIcon name="person"/>
              </EuiButton>
            </template>
            <EuiList>
              <EuiListItem :button="true" @click.self="$router.push({ name: 'profile.index' })">
                {{ $t('button.profile') }}
              </EuiListItem>
              <EuiListItem v-if="isDomainSelectable" :button="true" @click="selectDomain">
                {{ $t('header.userOptions.role') }}
              </EuiListItem>
              <EuiListItem :button="true" @click="toLogout">
                {{ $t('button.logout') }}
              </EuiListItem>
            </EuiList>
          </EuiDropdownMenu>
        </EuiHeaderMobile>
        <EuiBreadcrumb v-if="breadcrumbs && breadcrumbs.length > 0">
          <EuiBreadcrumbItem v-for="(breadcrumb, index) in breadcrumbs"
                             :key="index"
                             :class="{ 'eui-u-mr-1': index !==  breadcrumbs.length - 1 }"
                             :selected="index ===  breadcrumbs.length - 1"
                             @click="$router.push({ name: breadcrumb.link })">
            <EuiIcon v-if="breadcrumb.icon" :name="breadcrumb.icon"/>
            {{ typeof breadcrumb.name === 'object' ? breadcrumb.name[1] : breadcrumb.name }}
            <template v-if="index !==  breadcrumbs.length - 1"> > </template>
          </EuiBreadcrumbItem>
        </EuiBreadcrumb>
      </template>
      <template #sidenav>
        <EuiSidenav v-model="isSidenavOpen" :modal="isMobileView" v-bind:miniVariant.sync="isMiniVariant">
          <router-link to="/" style="text-decoration: none;">
            <EuiSidenavHeader>
              <div class="flex items-baseline">
                <img v-if="isMiniVariant" width="59" height="65" src="/logo_blue_collapse.svg" alt="edoc sign logo miniature">
                <img v-else width="190" height="60" src="/logo_blue.svg" alt="edocsign logo"/>
              </div>
            </EuiSidenavHeader>
          </router-link>
          <component :is="dynamicNavigation">
            <component :is="dynamicNavigationItem" :to="{ name: 'dashboard'}">
              <template #graphic>
                <EuiIcon size="medium" name="home"/>
              </template>
              {{ $t('dashboard.aside-menu.title') }}
            </component>
            <component :is="dynamicNavigationSection" to="/signworkflows/inbox">
              <template #header>
                <EuiIcon size="medium" name="sign"/>
                {{ $t('signworkflows.aside-menu.title') }}
              </template>
              <EuiListSubheader>{{ $t('signworkflows.aside-menu.label.yourSignbooks') }}</EuiListSubheader>
              <EuiNavigationItem disc :to="{ name: 'paraphers.create.addParameters' }">{{ $t('signworkflows.create-signworkflow') }}</EuiNavigationItem>
              <EuiNavigationItem disc to="/signworkflows/inbox">{{ $t('signworkflows.tab.inbox') }}
                <template #meta>
                  <EuiBadge color="primary" inverted v-if="usageDatas && usageDatas['inbox']" :content="parseInt(usageDatas['inbox'])" :max="99"/>
                </template>
              </EuiNavigationItem>
              <EuiNavigationItem disc to="/signworkflows/sent">{{ $t('signworkflows.tab.sent') }}
                <template #meta>
                  <EuiBadge color="primary" inverted v-if="usageDatas && usageDatas['sent']" :content="parseInt(usageDatas['sent'])" :max="99"/>
                </template>
              </EuiNavigationItem>
              <template v-if="usageDatas">
                <EuiListSubheader>{{ $t('signworkflows.aside-menu.label.allSignbooks') }}</EuiListSubheader>
                <EuiNavigationItem disc to="/signworkflows/waiting-for-action">
                  {{ $t('signworkflows.status.to-validate') }}
                  <template #meta>
                    <EuiBadge color="primary" inverted :content="parseInt(usageDatas.nbWaitingForActions)" :max="99"/>
                  </template>
                </EuiNavigationItem>
                <EuiNavigationItem disc to="/signworkflows/waiting-for-others">
                  {{ $t('signworkflows.status.waiting-for-others') }}
                  <template #meta>
                    <EuiBadge color="primary" inverted :content="parseInt(usageDatas.nbWaitingForOthers)" :max="99"/>
                  </template>
                </EuiNavigationItem>
                <EuiNavigationItem disc to="/signworkflows/expire-soon">
                  {{ $t('signworkflows.status.expire-soon') }}
                  <template #meta>
                    <EuiBadge color="primary" inverted :content="parseInt(usageDatas.nbExpireSoon)" :max="99"/>
                  </template>
                </EuiNavigationItem>
                <EuiNavigationItem disc to="/signworkflows/expired">
                  {{ $t('signworkflows.status.expired') }}
                  <template #meta>
                    <EuiBadge color="primary" inverted :content="parseInt(usageDatas.nbExpired)" :max="99"/>
                  </template>
                </EuiNavigationItem>
                <EuiNavigationItem disc to="/signworkflows/completed">
                  {{ $t('signworkflows.status.completed') }}
                  <template #meta>
                    <EuiBadge color="primary" inverted :content="parseInt(usageDatas.nbCompletedTotal)" :max="99"/>
                  </template>
                </EuiNavigationItem>
                <EuiNavigationItem disc to="/signworkflows/archived">
                  {{ $t('signworkflows.tab.archive') }}
                  <template #meta>
                    <EuiBadge color="primary" inverted :content="parseInt(usageDatas['archived'])" :max="99"/>
                  </template>
                </EuiNavigationItem>
              </template>
            </component>
            <component :is="dynamicNavigationSection" to="/templates" v-if="canManageModels">
              <template #header>
                <EuiIcon size="medium" name="file"/>
                {{ $t('templates.title') }}
              </template>
              <EuiNavigationItem disc to="/templates">
                {{ $t('templates.index.aside-menu.item.all') }}
              </EuiNavigationItem>
            </component>
            <component :is="dynamicNavigationSection" to="/signatories">
              <template #header>
                <EuiIcon size="medium" name="group"/>
                {{ $t('signatories.index.aside-menu.title') }}
              </template>
              <EuiNavigationItem disc to="/signatories/internal">
                {{ $t('signatories.index.aside-menu.item.users') }}
              </EuiNavigationItem>
              <EuiNavigationItem disc to="/signatories/external">
                {{ $t('signatories.index.aside-menu.item.external') }}
              </EuiNavigationItem>
              <EuiNavigationItem disc to="/signatories/social">
                {{ $t('signatories.index.aside-menu.item.social') }}
              </EuiNavigationItem>
            </component>
            <component :is="dynamicNavigationSection" to="/settings" v-if="$store.state.ModuleEdocSign.auth.profile.features && ($store.state.ModuleEdocSign.auth.profile.features.hasAccessRulesPermission === '1' || $store.state.ModuleEdocSign.auth.profile.features.hasEditUserPermission === '1')">
              <template #header>
                <EuiIcon size="medium" name="person"/>
                {{ $t('settings.index.aside-menu.title') }}
              </template>
              <EuiNavigationItem disc to="/settings/users">
                {{ $t('settings.index.aside-menu.item.users') }}
              </EuiNavigationItem>
              <EuiNavigationItem disc to="/settings/groups" v-if="$store.state.ModuleEdocSign.auth.profile.features && $store.state.ModuleEdocSign.auth.profile.features.hasAccessRulesPermission === '1'">
                {{ $t('settings.index.aside-menu.item.groups') }}
              </EuiNavigationItem>
            </component>
          </component>
        </EuiSidenav>
      </template>
      <router-view/>
    </EuiLayout>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import interceptors from '@/interceptors';
import AlertManager from '@/components/alerts/AlertManager';

export default {
  name: 'App',
  data() {
    return {
      status: [
        'inbox',
        'sent',
        'waiting-for-action',
        'waiting-for-others',
        'expire-soon',
        'expired',
        'completed',
        'archived',
      ],
      searchParams: {
        offset: 0,
        limit: 1,
      },
    };
  },
  mixins: [interceptors],
  components: {
    SelectDomainDialog: () => import(/* webpackPrefetch: true */ '@/components/dialogs/SelectDomain'),
    AlertManager,
  },
  watch: {
    isDesktopView(value) {
      this.isMiniVariant = !value;
    },
    isMobileView(value) {
      this.isSidenavOpen = !value;
    },
  },
  computed: {
    breadcrumbs: {
      cache: false,
      get() {
        return this.$route.meta.breadcrumbs ? this.$route.meta.breadcrumbs.map((item) => {
          const breadcrumb = item;
          breadcrumb.name = item.signatoryName ? item.signatoryName(this.$route) : item.name;
          return breadcrumb;
        }) : [];
      },
    },
    ...mapState({
      isMobileView: (state) => state.application.responsive.xs,
      isDesktopView: (state) => state.application.responsive.lg,
      routeName: (state) => state.route.name,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
      isDeskRhUserComingFromStepLogin: (state) => state.ModuleEdocSign.auth.isDeskRhUserComingFromStepLogin,
      signworkflowsStatus: (state) => state.ModuleEdocSign.parapheurs.status,
    }),
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
      usageDatas: 'ModuleEdocSign/auth/usageDatas',
      menusAreOpened: 'ModuleEdocSign/ui/menusAreOpened',
      options: 'ModuleEdocSign/auth/options',
      loading: 'application/loading',
      isDomainSelectable: 'ModuleEdocSign/auth/isDomainSelectable',
    }),
    domainName() {
      const { current } = this.$store.state.ModuleEdocSign.auth.domains;
      return current && current.name ? current.name : this.$i18n.t('app.name');
    },
    isSidenavOpen: {
      get() {
        return this.$store.state.ModuleEdocSign.ui.isSidenavOpen;
      },
      set(value) {
        this.$store.commit('ModuleEdocSign/ui/UPDATE_IS_SIDENAV_OPEN', value);
      },
    },
    isMiniVariant: {
      get() {
        return this.$store.state.ModuleEdocSign.ui.isMiniVariant;
      },
      set(value) {
        this.$store.commit('ModuleEdocSign/ui/UPDATE_IS_MINI_VARIANT', value);
      },
    },
    showSelectDomainDialog: {
      get() {
        return this.$store.state.application.showSelectDomainDialog;
      },
      set(value) {
        if (value === true) {
          this.$store.commit('application/SHOW_SELECT_DOMAIN');
        } else {
          this.$store.commit('application/CLOSE_SELECT_DOMAIN');
        }
      },
    },
    isGuest() {
      return this.$route.meta.guest && this.$store.state.ModuleEdocSign.auth.session.guest;
    },
    isDeskRhUser() {
      return this.isDeskRhUserComingFromStepLogin;
    },
    canManageModels() {
      let allowed = true;
      if (this.options && this.options.manageSignbookModels) {
        allowed = parseInt(this.options.manageSignbookModels.value);
      }
      if (allowed) {
        allowed = !!this.$store.state.ModuleEdocSign.auth.profile.features && this.$store.state.ModuleEdocSign.auth.profile.features.hasReadModelPermission === '1';
      }
      return allowed;
    },
    dynamicNavigation() {
      if (this.isMiniVariant) {
        return 'EuiNavigationMini';
      }
      return 'EuiNavigation';
    },
    dynamicNavigationItem() {
      if (this.isMiniVariant) {
        return 'EuiNavigationMiniItem';
      }
      return 'EuiNavigationItem';
    },
    dynamicNavigationSection() {
      if (this.isMiniVariant) {
        return 'EuiNavigationSectionMini';
      }
      return 'EuiNavigationSection';
    },
  },
  methods: {
    ...mapActions({
      updateMenusAreOpened: 'ui/updateMenusAreOpened',
      logout: 'ModuleEdocSign/auth/logout',
      storeGetSignworkflows: 'ModuleEdocSign/parapheurs/getSignworkflows',
      getUserInfos: 'ModuleEdocSign/auth/getUserInfos',
      storeGetUsageDatas: 'ModuleEdocSign/auth/getUsageDatas',
    }),
    selectDomain() {
      this.$store.commit('application/SHOW_SELECT_DOMAIN');
    },
    toLogout() {
      this.logout()
        .then(() => {
          this.$router.push({ name: 'login' });
        });
    },
    getAllSignworkflowsStatusCounts() {
      return Promise.all(this.status
        .map((status) => this.storeGetSignworkflows({
          ...this.searchParams,
          params: [{
            paramName: 'status',
            paramValue: status,
          }],
        })));
    },
  },
  mounted() {
    this.isMiniVariant = !this.isDesktopView;
    this.getUserInfos().then(() => {
      if (this.isDeskRhDomain) {
        this.getAllSignworkflowsStatusCounts();
      } else {
        this.storeGetUsageDatas();
      }
    });
  },
};
</script>
